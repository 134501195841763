import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CustomButton from "src/components/CustomButton";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Controller, Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputWithLabel from "src/components/InputWithLabel";
import { GENDER_OPTIONS, errorCode } from "src/constants/common";
import { s3Upload } from "src/utils/s3Operations";
import { s3Path } from "src/utils/s3Path";
import { axiosPatch } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";
import { convertBase64ToFile, getShortName } from "src/utils/global-functions";
import { format, parse } from "date-fns";
import Number from "src/components/Number";
import { currentUser, me } from "src/redux/reducers/common/Common.slice";
import { Trash01 } from "@untitled-ui/icons-react/build/cjs";
import DateSelect from "src/components/DateSelect/DateSelect";
import { iconDateCalendar } from "src/theme/Images";

interface ILanding {
    handleClose: () => void;
}
interface IFormInput {
    first_name: string;
    last_name: string;
    profile_image_name: any;
    birthdate: string;
    // gender: string;
}
interface IGenderOptions {
    value: string;
    label: string;
}
const AccountSetting = ({ handleClose }: ILanding) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    // const [customGenderOption, setCustomGenderOption] = useState<IGenderOptions[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        first_name: Yup.string().required(t("This field is required")),
        last_name: Yup.string().required(t("This field is required")),
        profile_image_name: Yup.object().nullable(),
        /* profile_image_name: Yup.object()
            .nullable()
            .shape({
                name: Yup.string().required(t("This field is required")),
                file: Yup.string().nullable(),
                url: Yup.string().required(t("This field is required")),
            })
            .test("image-validation", t("This field is required"), function (value) {
                if (!value) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t("This field is required"),
                    });
                }
                const { name, url } = value;
                if (!name || !url) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t("This field is required"),
                    });
                }
                return true;
            }), */
        birthdate: Yup.string().required(t("This field is required")),
        // gender: Yup.string().required(t("Please select gender")),
    }).required();

    const { control, handleSubmit, setError, setValue, watch } = useForm<IFormInput>({
        resolver: yupResolver(schema) as Resolver<IFormInput>,
        defaultValues: {
            first_name: user?.first_name || "",
            last_name: user?.last_name || "",
            profile_image_name: user?.profile_image_url ? { name: user?.profile_image_name, url: user?.profile_image_url } : null,
            birthdate: user?.birthdate ? format(parse(user.birthdate, "yyyy-MM-dd", new Date()), "dd-MM-yyyy") : "",
            // gender: user?.gender,
        },
    });

    const watchProfileImage = watch("profile_image_name");

    // useEffect(() => {
    //     const userGender = user?.gender ?? "";
    //     const foundOption = GENDER_OPTIONS.find((go) => go.value === userGender);
    //     if (!foundOption && userGender) {
    //         const customOption = {
    //             label: userGender,
    //             value: userGender,
    //         };
    //         setCustomGenderOption([...GENDER_OPTIONS, customOption]);
    //     } else {
    //         setCustomGenderOption(GENDER_OPTIONS);
    //     }
    // }, [user]);

    const handleSave = async (data: IFormInput) => {
        setIsLoading(true);
        try {
            let imageStatus = errorCode.updateSuccess;
            if (watchProfileImage?.file && watchProfileImage?.name) {
                const logoImageResponse: any = await s3Upload(watchProfileImage?.file, `${s3Path.USER_PROFILE}${watchProfileImage?.name}`);
                imageStatus = logoImageResponse?.status;
            }
            if (imageStatus === errorCode.updateSuccess) {
                const payload = {
                    ...data,
                    profile_image_name: watchProfileImage?.name,
                    birthdate: format(parse(data.birthdate, "dd-MM-yyyy", new Date()), "Y-MM-dd"),
                };
                const response = await axiosPatch(API.USER.PROFILE, payload);
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    await dispatch(me());
                    // handleClose();
                    return;
                }
                if (response.data?.status === errorCode.unprocessable) {
                    Object.keys(response.data.data).forEach((field) => {
                        setError(field as any, {
                            type: "manual",
                            message: response.data.data[field][0],
                        });
                    });
                    return;
                }
                throw response.data;
            }
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const onChangeCrop = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const reader = new FileReader();
        reader.onloadstart = () => {};
        reader.onloadend = () => {
            const file = convertBase64ToFile(reader.result);
            if (file && file.filename && file.convertedFile) {
                setValue("profile_image_name", {
                    file: file.convertedFile as File,
                    url: reader.result as string,
                    name: file.filename,
                });
            }
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
    };

    return (
        <div className="relative h-full w-full flex flex-col font-primary">
            <form onSubmit={handleSubmit(handleSave)} className="flex flex-col flex-1">
                <div className="flex flex-col">
                    <div className="text-[20px] mb-1.5 leading-[28px] font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">Account Settings</div>
                    <div className="font-normal text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">Manage your profile and security</div>
                </div>
                <Controller
                    name="profile_image_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="flex flex-row justify-between items-center mt-6">
                            <div className="relative flex gap-5 items-center">
                                <input type="file" id="image" className=" absolute opacity-0 rounded-full h-[64px] w-[64px]" onChange={onChangeCrop} />
                                <label htmlFor="image" className="relative h-[74px] w-[74px] rounded-full">
                                    {value && value.url ? (
                                        <img src={value.url} alt="" className="relative h-[74px] w-[74px] rounded-primary object-cover" />
                                    ) : (
                                        <div className="rounded-primary border-[3px] border-white bg-[#F2F4F7] flex justify-center items-center shadow-[0px_0px_8px_2px_#AAAAAA26] text-primaryAppointment font-medium  w-[80px] h-[80px] ">
                                            <span className="text-[24px] leading-[36px] font-medium">{getShortName(user?.full_name as string)}</span>
                                        </div>
                                    )}
                                </label>
                                <div className="flex flex-col">
                                    <span className="flex text-txtAppointmentColor text-base leading-[22.4px] font-medium mb-1 -tracking-[0.03rem]">Your profile picture</span>
                                    <label htmlFor="image" className="text-primaryAppointment text-sm cursor-pointer leading-[22.4px] font-medium -tracking-[0.03rem]">
                                        Add a profile picture
                                    </label>
                                </div>
                            </div>
                            <div className="w-[36px] h-[36px] rounded-secondary cursor-pointer hover:bg-[#FEF3F2] border border-[#F9E0E0] flex justify-center items-center">
                                <Trash01 onClick={() => setValue("profile_image_name", null)} className="text-danger w-[18px] h-[18px]" />
                            </div>
                            {/* {value && value.url && (
                                            <ImageInfo className="w-[350px] h-[72px] mt-[6px] relative" name={value.name} url={value?.url} onRemove={handleRemoveImage} isApperance />
                                        )} */}

                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <div className="w-full flex flex-row mt-6 gap-2">
                    <Controller
                        name="first_name"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="w-1/2 ">
                                <InputWithLabel label={t("First Name")} type="text" placeholder={t("Enter your first name")} handleChange={onChange} value={value} name="first_name" />
                                {error && <p className="text-red-500">{error.message}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="last_name"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="w-1/2">
                                <InputWithLabel label={t("Last Name")} type="text" placeholder={t("Enter your last name")} handleChange={onChange} value={value} name="last_name" />
                                {error && <p className="text-red-500">{error.message}</p>}
                            </div>
                        )}
                    />
                </div>

                <div className="w-full mt-[14px] ">
                    <InputWithLabel label={t("Email")} disabled type="text" handleChange={() => {}} value={user?.email as string} name="email" />
                </div>
                <div className="w-full mt-[14px]">
                    <div className="flex flex-wrap !border-line">
                        <label className="text-txtAppointmentColor text-sm font-normal leading-[18.2px] -tracking-[0.03rem] mb-1.5 block">Phone Number </label>
                        <Number getNumber={() => {}} onNumberChange={() => {}} disabled defaultCode={user?.phone_country_code} defaultPhone={user?.phone} />
                    </div>
                </div>
                <div className="mt-[14px]">
                    <Controller
                        name="birthdate"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <>
                                <div className="w-full">
                                    <DateSelect
                                        label={t("Date of birth")}
                                        inputName={"birthdate"}
                                        className="form_control_date w-full form_input_control"
                                        placeholder="dd-MM-yyyy"
                                        dateFormat="dd-MM-yyyy"
                                        onChangeValue={onChange}
                                        InputClass={`${error ? "is-invalid" : ""}`}
                                        icon={<img src={iconDateCalendar} alt="" className="w-5 " />}
                                        value={value}
                                    />
                                </div>
                                {error && <p className="text-red-500">{error.message}</p>}
                            </>
                        )}
                    />
                </div>
                <div className="mt-auto border-t mb flex gap-3 bg-contentBackground pt-4 mb-4 -mx-[24px] px-[24px]">
                    <CustomButton outlineSecondary className="w-1/2" disabled={isLoading} onClick={handleClose}>
                        Cancel
                    </CustomButton>
                    <CustomButton primary className="w-1/2" type="submit" isLoading={isLoading} disabled={isLoading}>
                        Save changes
                    </CustomButton>
                </div>
            </form>
        </div>
    );
};

export default AccountSetting;
