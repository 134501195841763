import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { addProduct, getBookingInfo, removeProduct } from "../Booking.slice";
import { useTranslation } from "react-i18next";
import ProductCard from "./ProductCard";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import ProductDetail from "./ProductDetail";
import Sidebar from "../../../components/Sidebar";
import { IInitAction, initAction } from "./Product.interface";
import { currentShop } from "src/redux/reducers/common/Common.slice";

const ProductBooking = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const booking = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const shop = useAppSelector(currentShop);
    const [allProducts, setAllProducts] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [product, setProduct] = useState<any>(null);
    const [action, setAction] = useState<IInitAction>(initAction);

    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        if (!uiState.isMobile) {
            handleClose();
        }
    }, [uiState.isMobile]);

    useEffect(() => {
        if (allProducts.length) {
            let aggregatedProducts = booking.selectedProducts.reduce((acc: any, item: any) => {
                const id = item.selectedVariant.inventory.id;
                if (acc[id]) {
                    acc[id].quantity += 1;
                } else {
                    acc[id] = {
                        ...item,
                        id,
                        quantity: 1,
                    };
                }
                return acc;
            }, {});
            aggregatedProducts = Object.values(aggregatedProducts);

            const updatedProducts = allProducts.reduce((acc: any[], allProduct: any) => {
                const variants = allProduct.variants.reduce((variantsAcc: any[], variant: any) => {
                    const inventories = variant.inventories.reduce((inventoriesAcc: any[], inventory: any) => {
                        const aggregatedProduct = aggregatedProducts.find((item: any) => item.id === inventory.id);
                        const quantity = inventory.in_stock_quantity - (aggregatedProduct ? aggregatedProduct.quantity : 0);

                        if (quantity > 0) {
                            inventoriesAcc.push({ ...inventory, in_stock_quantity: quantity });
                        }
                        return inventoriesAcc;
                    }, []);

                    if (inventories.length > 0) {
                        variantsAcc.push({ ...variant, inventories, inventory: inventories[0] });
                    }
                    return variantsAcc;
                }, []);

                if (variants.length > 0) {
                    acc.push({ ...allProduct, variants });
                }
                return acc;
            }, []);

            setProducts(updatedProducts);
        }
    }, [booking, allProducts]);

    const getProducts = () => {
        const params = {
            shop_id: shop.id,
            location_id: booking.selectedShopLocation?.id,
        };
        const payload = {
            booking_id: booking.modifyingAppointmentId || null,
        };
        axiosGet(API.PRODUCT.LIST, payload, params).then((response) => setAllProducts(response.data.data));
    };

    const handleAction = (type: keyof IInitAction, data?: any) => (event: any) => {
        event.stopPropagation();
        if (type === "product") {
            setProduct(data);
        }
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    const manageCart =
        (type: string, index: number = 0) =>
        (item: any, variant: any) =>
        () => {
            if (type === "add") {
                dispatch(addProduct({ ...item, selectedVariant: variant }));
            } else {
                dispatch(removeProduct(index));
            }
        };

    return (
        <div className="h-full relative">
            <div className="title text-txtcolor font-medium text-2xl leading-[128%] flex gap-3  mt-5 pb-1.5 items-center">
                {/* <div
                    className=" flex justify-center items-center text-txtcolor h-8 w-8 cursor-pointer border border-borderColorSecondary rounded-primary shadow-InputAndButton"
                    onClick={() => handleStep(EBookingTabState.TAB_MENU)}
                >
                    <ArrowLeft className="text-txtAppointmentColor w-[18px]" />
                </div> */}
                {/* <span className="location-title-text">{t("View our products")}</span> */}
            </div>
            <div className="max-h-[calc(100%-(64px+36px))] pb-[70px] lg:pb-4  overflow-y-scroll scrollbar-hide">
                {booking.selectedProducts.length > 0 && (
                    <>
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4 3xl:grid-cols-5 mt-[14px] gap-4 mb-3">
                            {booking.selectedProducts.map((item, index) => (
                                <ProductCard key={`${item.id} - ${index}`} product={item} onClick={manageCart("remove", index)} handleAction={handleAction} isSelected />
                            ))}
                        </div>
                        <div className="font-medium font-base text-txtAppointmentColor py-3  -tracking-[0.03rem]">{t("Additional products you want to add")}</div>
                    </>
                )}
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4 3xl:grid-cols-5 content-baseline gap-4 mt-4 h-full">
                    {products.map((item) => (
                        <ProductCard key={item.id} product={item} onClick={manageCart("add")} handleAction={handleAction} />
                    ))}
                </div>
            </div>
            <Sidebar isOpen={action.product} isBottomView={uiState.isMobile} position={uiState.isMobile ? "bottom" : "right"} size={uiState.isMobile ? "" : "w-[460px]"} handleClose={handleClose}>
                {action.product && (
                    <div className={uiState.isMobile ? "relative" : ""}>
                        <ProductDetail data={product} />
                    </div>
                )}
            </Sidebar>
            {uiState.isMobile && <ManageSubmit />}
        </div>
    );
};

export default ProductBooking;
