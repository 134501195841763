import React, { useState, useEffect } from "react";
import AddNewCardTabComponent from "./AddNewCardTabComponent";
import Sidebar from "src/components/Sidebar";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import { HiPlus } from "react-icons/hi";
import { API } from "../../../constants/api";
import { axiosGet, axiosPost } from "../../../utils/requestClient";
import { toast } from "react-toastify";
import { errorCode } from "../../../constants/common";
import { getCardTypeImage } from "../../../utils/global-functions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { LoyaltyPoint } from "../../../components/SvgColor/svgIcons";
import { setIsShowFinalizeBooking, setIsShowNoShowPolicy, setIsShowOrderSummary, setPaymentType, setPaymentOption, getBookingInfo, setIsValidPaymentType } from "../Booking.slice";
import { useTranslation } from "react-i18next";
import { calculateTotalTaxes } from "../../../utils/global-functions";
import { BiCreditCard } from "react-icons/bi";
import { BsHouse } from "react-icons/bs";
import AppointmentPreview from "../AppointmentPreview";
import NoShowPolicy from "../NoShowPolicy";
import FlowbiteModal from "src/components/FlowbiteModal";
import { IProps, OptionType, PaymentMethod } from "./Payment.interface";
import { currentUser } from "src/redux/reducers/common/Common.slice";
import { ArrowLeft } from "@untitled-ui/icons-react/build/cjs";

const FinalizeBooking = ({ handleBackToBooking }: IProps) => {
    const [isShowAddNewCardTab, setIsShowAddNewCardTab] = useState(false);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isLoadCard, setIsLoadCard] = useState(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const booking = useAppSelector(getBookingInfo);
    const user = useAppSelector(currentUser);
    const [isUserCardLoading, setIsUserCardLoading] = useState(false);
    const [userCardList, setUserCardList] = useState([]);
    const [defaultCardId, setDefaultCardId] = useState<string | undefined>(undefined);
    const [servicesSubTotalPrice, setServicesSubTotalPrice] = useState("");
    const [servicesSubTotalTax, setServicesSubTotalTax] = useState("0.00");
    const [isLoyaltyPoint, setIsLoyaltyPoint] = useState(false);
    const [selectedOption, setSelectedOption] = useState<OptionType>("pay_now"); //<OptionType>(userCardList.length > 0 ? "stripe" : "cash");
    const [selectPaymentMethod, setPaymentMethod] = useState<PaymentMethod>("stripe"); //<PaymentMethod>(userCardList.length > 0 ? "pay_online" : "pay_at_shop");

    useEffect(() => {
        if (user) {
            const totalAmount = (parseFloat(servicesSubTotalPrice) + parseFloat(servicesSubTotalTax)).toFixed(2);
            setIsLoyaltyPoint(totalAmount <= user.total_loyalty_amount ? true : false);
        }
    }, [user, servicesSubTotalPrice, servicesSubTotalTax]);

    useEffect(() => {
        loadStripeCardList();

        const totalPrices = booking.selectedServices.reduce((prevPrice, serviceRecord) => {
            if (serviceRecord.price && serviceRecord.quantity) {
                return prevPrice + Number(serviceRecord.price?.price) * serviceRecord.quantity;
            }
            return prevPrice;
        }, 0);
        setServicesSubTotalPrice(totalPrices.toFixed(2));
        if (totalPrices && booking.selectedShopLocation?.sales_tax) {
            const subTotalTax = calculateTotalTaxes(
                totalPrices.toFixed(2),
                Number(booking.selectedShopLocation?.sales_tax?.gst),
                Number(booking.selectedShopLocation?.sales_tax?.hst),
                Number(booking.selectedShopLocation?.sales_tax?.pst),
            );
            setServicesSubTotalTax(subTotalTax);
        } else {
            setServicesSubTotalTax("0.00");
        }

        return () => {
            dispatch(setIsValidPaymentType(false));
        };
    }, []);

    useEffect(() => {
        if (isLoadCard) {
            loadStripeCardList();
            setIsLoadCard(false);
            dispatch(setIsValidPaymentType(false));
        }
    }, [isLoadCard]);

    const loadStripeCardList = async () => {
        try {
            setIsUserCardLoading(true);
            const response = await axiosGet(API.STRIPE.CARD_LIST);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setUserCardList(response.data.data.cards);
                setDefaultCardId(response.data.data.default_card_id);
                if (response.data.data.cards.length > 0) {
                    setPaymentMethod("stripe");
                    setSelectedOption("pay_now");
                    dispatch(setPaymentType("stripe"));
                    dispatch(setPaymentOption("pay_now"));
                }
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please try again");
        } finally {
            setIsUserCardLoading(false);
        }
    };

    const handleMakeCardDefault = async (paymentMethodId: string) => {
        if (defaultCardId === paymentMethodId) {
            return;
        }
        setIsUserCardLoading(true);
        axiosPost(API.STRIPE.CARD_DEFAULT, { payment_method_id: paymentMethodId })
            .then(() => setDefaultCardId(paymentMethodId))
            .catch((error: any) => toast.error(error?.message ?? "Something went wrong, Please try again"))
            .finally(() => setIsUserCardLoading(false));
    };

    const handleBackToBookingScreen = () => {
        dispatch(setIsShowFinalizeBooking(false));
        handleBackToBooking();
    };

    const handleOptionChange = async (option: OptionType) => {
        setSelectedOption(option);
        dispatch(setPaymentOption(option));
    };

    const handlePaymentMethodChange =
        (paymentMethod: PaymentMethod, id: any = null) =>
        (event: any) => {
            event.stopPropagation();
            setPaymentMethod(paymentMethod);
            dispatch(setPaymentType(paymentMethod));
            if (id !== null) {
                handleMakeCardDefault(id);
                setDefaultCardId(id);
            }
        };

    return (
        <div className="h-full relative">
            <div className="title mb-5 text-txtcolor font-medium text-2xl leading-[124%]  mt-7 md:mt-7 flex gap-3 items-center">
                <div
                    className=" flex justify-center items-center text-txtcolor h-8 w-8 cursor-pointer border border-borderColorSecondary rounded-primary shadow-InputAndButton"
                    onClick={handleBackToBookingScreen}
                >
                    <ArrowLeft className="text-txtAppointmentColor w-[18px]" />
                </div>
                <div className="location-title-text pt-1"> Payment Options</div>
            </div>

            <div className="h-[calc(100vh-390px)] lg:h-[calc(100vh-300px)] overflow-auto scrollbar-hide">
                <div
                    // bg-contentBackground border border-lightgray aa class add karjo condition ma
                    className={` border border-borderColorPrimary  py-3 md:py-3 xl:py-3 pl-3 pr-6 flex gap-5 items-center justify-between rounded-secondary relative cursor-pointer hover:border-primary ${
                        selectedOption === "pay_now" ? "border-primary" : ""
                    }`}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOptionChange("pay_now");
                    }}
                >
                    <div className="flex gap-3 items-center w-full justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <div className="p-[5px] lg:p-[5px] xl:p-[5px] 2xl:p-[5px] border border-primary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton ">
                                <BiCreditCard color="var(--brand-color)" size={20} height={20} />
                            </div>
                            <h2 className="mt-[1px] text-base !leading-[22.4px] lg:text-xs lg:leading-4  xl:text-sm xl:leading-5 2xl:text-base 2xl:leading-[22.4px]  text-txtcolor font-medium  align-middle tracking-[0.03rem]">
                                {t("Pay Now")}
                            </h2>
                        </div>
                        <div className="mr-0">
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    id="loyaltyPointsSelect"
                                    className="cursor-pointer bg-primary"
                                    disabled={isLoyaltyPoint ? false : true}
                                    checked={selectedOption === "pay_now"}
                                    onChange={() => handleOptionChange("pay_now")}
                                    readOnly={true}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div
                    className={` border  py-3 md:py-3 xl:py-3 pl-3 pr-6 flex gap-5 items-center justify-between rounded-secondary relative cursor-pointer mt-3 hover:border-primary ${
                        selectedOption === "pay_later" ? "border-primary " : "bg-contentBackground border-borderColorPrimary"
                    }`}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOptionChange("pay_later");
                    }}
                >
                    <div className="flex gap-3 items-center w-full justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <div className="p-[5px] lg:p-[5px] xl:p-[5px] 2xl:p-[5px] border border-primary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton">
                                <BsHouse color="var(--brand-color)" size={20} height={20} />
                            </div>
                            <h2 className="mt-[1px] text-base !leading-[22.4px] lg:text-xs lg:leading-4  xl:text-sm xl:leading-5 2xl:text-base 2xl:leading-[22.4px]  text-txtcolor font-medium  align-middle tracking-[0.03rem]">
                                {t("Pay Later")}
                            </h2>
                        </div>
                        <div className="mr-0">
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    id="loyaltyPointsSelect"
                                    className="cursor-pointer bg-primary"
                                    disabled={isLoyaltyPoint ? false : true}
                                    checked={selectedOption === "pay_later"}
                                    onChange={() => handleOptionChange("pay_later")}
                                    readOnly={true}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                {/* <div className="bg-contentBackground border border-lightgray py-3 md:py-3 xl:py-3 pl-3 pr-6 flex gap-5 items-center justify-between rounded-xl relative cursor-pointer mt-3">
                    <div className="flex gap-3 items-center w-full justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <div className="p-[5px] lg:p-[5px] xl:p-[5px] 2xl:p-[5px] border custom-icon-border rounded-lg w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton">
                                <BiWallet color="var(--icon-color)" size={20} height={20} />
                            </div>
                            <h2 className="mt-1 text-base !leading-[22.4px] lg:text-xs lg:leading-4  xl:text-sm xl:leading-5 2xl:text-base 2xl:leading-[22.4px]  text-txtcolor font-medium align-middle">
                                {t("Deposit")}
                            </h2>
                        </div>
                        <div className="mr-0">
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    id="loyaltyPointsSelect"
                                    className="cursor-pointer bg-primary"
                                    disabled={isLoyaltyPoint ? false : true}
                                    checked={selectedOption === "loyalty"}
                                    onChange={() => handleOptionChange("loyalty", "loyalty_points")}
                                    readOnly={true}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div> */}
                <div className="mt-5 pb-6 w-full">
                    <div className="mb-3 text-txtcolor font-bold text-base">{t("Payment Method")}</div>
                    {user && user.total_loyalty_points > 0 && (
                        <label
                            htmlFor="loyalty-points"
                            className={`flex justify-between bg-contentBackground border py-3 md:py-3 xl:py-3 pl-3 pr-6 flex gap-5 items-center justify-between rounded-secondary relative hover:border-primary ${
                                isLoyaltyPoint ? "cursor-pointer" : ""
                            } ${selectPaymentMethod === "loyalty" ? "border-primary" : "border-borderColorPrimary"}`}
                        >
                            <div className="flex gap-3 items-center w-full justify-between">
                                <div className={`flex flex-row items-center gap-5`}>
                                    <div className="p-[10px] lg:p-[8px] xl:p-[9px] 2xl:p-[10px] border border-primary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton">
                                        <LoyaltyPoint fillColor="var(--brand-color)" />
                                    </div>
                                    <div>
                                        <p className={`font-medium text-sm text-${isLoyaltyPoint ? "txtcolor" : "txtAppointmentColor"}`}>{t("Loyalty points")}</p>
                                        <p className="text-textGrey text-xs font-medium">
                                            {t("Available")}
                                            {" : "}
                                            {user.total_loyalty_points} {t("points")}
                                        </p>
                                    </div>
                                </div>
                                <div className="mx-0">
                                    <label className="radio-container">
                                        <input
                                            type="radio"
                                            id="loyalty-points"
                                            disabled={isLoyaltyPoint ? false : true}
                                            checked={selectPaymentMethod === "loyalty"}
                                            onChange={handlePaymentMethodChange("loyalty")}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </label>
                    )}
                    {userCardList.length > 0 ? (
                        userCardList.map((userCard: any) => (
                            <label
                                key={userCard.id}
                                htmlFor={`card-${userCard.id}`}
                                className={`flex justify-between bg-contentBackground border py-3 md:py-3 xl:py-3 pl-3 pr-6 flex gap-5 items-center justify-between rounded-secondary relative hover:border-primary mt-3 cursor-pointer ${
                                    defaultCardId === userCard.id && selectPaymentMethod === "stripe" ? "border-primary" : "border-borderColorPrimary"
                                }`}
                            >
                                <div className="flex gap-3 items-center w-full justify-between">
                                    <div className="flex flex-row items-center gap-5">
                                        <div className="p-[5px] lg:p-[8px] xl:p-[9px] 2xl:p-[5px] border border-primary rounded-secondary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton">
                                            <img className=" w-[32px] h-[32px] object-contain object-center" src={getCardTypeImage(userCard.card.brand)} alt="" />
                                        </div>
                                        <div className="text-txtcolor leading-[140%] font-medium text-sm flex flex-col">
                                            <p className="text-[14px] capitalize tracking-[0.03rem]">
                                                {userCard.card.brand} card ending {userCard.card.last4}
                                            </p>
                                            {defaultCardId === userCard.id && <p className="text-txtAppointmentColor text-xs font-medium tracking-[0.03rem]">{t("Default")}</p>}
                                        </div>
                                    </div>
                                    <div className="mx-0">
                                        <label className="radio-container">
                                            <input
                                                type="radio"
                                                id={`card-${userCard.id}`}
                                                checked={defaultCardId === userCard.id && selectPaymentMethod === "stripe"}
                                                onChange={handlePaymentMethodChange("stripe", userCard.id)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </label>
                        ))
                    ) : (
                        <div
                            className={`bg-contentBackground border py-3 md:py-3 xl:py-3 tracking-[0.03rem] pl-3 pr-6 flex gap-5 items-center justify-between rounded-secondary relative mt-3 h-[66px] ${
                                booking.isValidPaymentType ? "border-danger" : "border-borderColorPrimary"
                            }`}
                        >
                            <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1 tracking-[0.03rem]">
                                {isUserCardLoading ? t("Loading") : t("Card no available")}
                            </p>
                        </div>
                    )}
                    <div className="flex justify-end mt-3">
                        <label className="text-primary font-medium cursor-pointer flex items-center text-sm tracking-[0.03rem]" onClick={() => setIsShowAddNewCardTab(true)}>
                            <HiPlus size={16} className="mr-2 mt-0" />
                            <div className="pt-1"> {t("Add payment method")}</div>
                        </label>
                    </div>
                </div>
                {/* {userCardList.length > 0 ? (
                        <div className="flex w-full gap-3 bg-[#F6F8FE] p-2.5 border-0 rounded-xl  items-center">
                            <BiInfoCircle size={20} className="text-primary" />
                            <p className="text-sm leading-[140%] text-primary">
                                {t(
                                    "Your payment will be processed only after you accept the offer and select a date. The card added to the file will be used to secure your order. You will not be charged until you accept the order.",
                                )}{" "}
                                {booking.selectedSpecialist?.shop_staff?.[0]?.cancellation_window} {t("hours of the reservation.")}
                            </p>
                        </div>
                    ) : (
                        ""
                    )} */}
            </div>
            <Sidebar
                isOpen={booking.isShowOrderSummary && uiState.isMobile}
                handleClose={() => dispatch(setIsShowOrderSummary(false))}
                position={`${uiState.isMobile ? "bottom" : "right"}`}
                size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`} // w-[460px]"
                customClass=""
                isBottomView={uiState.isMobile ? true : false}
            >
                <AppointmentPreview />
            </Sidebar>
            <Sidebar
                isOpen={booking.isShowNoShowPolicy && uiState.isMobile}
                handleClose={() => dispatch(setIsShowNoShowPolicy(false))}
                position={`${uiState.isMobile ? "bottom" : "right"}`}
                size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`}
                customClass=""
                isBottomView={uiState.isMobile ? true : false}
            >
                <NoShowPolicy />
            </Sidebar>
            {booking.isShowNoShowPolicy && !uiState.isMobile && (
                <FlowbiteModal
                    handleClose={() => dispatch(setIsShowNoShowPolicy(false))}
                    size="md"
                    position="bottom-right"
                    className="mr-10 sm:w-[480px] lg:w-[480px] 2xl:w-[480px] 3xl:min-w-[580px] h-[412px] 2xl:right-[86px] 3xl:right-[180px] lg:right[86px] sm:right-[86px]"
                >
                    <NoShowPolicy />
                </FlowbiteModal>
            )}
            {isShowAddNewCardTab && (
                <Sidebar
                    isOpen={isShowAddNewCardTab}
                    handleClose={() => setIsShowAddNewCardTab(false)}
                    position={`${uiState.isMobile ? "bottom" : "right"}`}
                    size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`} // w-[460px]"
                    customClass=""
                    isBottomView={uiState.isMobile ? true : false}
                    handleBack={() => setIsShowAddNewCardTab(false)}
                >
                    <AddNewCardTabComponent
                        closeAddNewCardTab={() => {
                            setIsShowAddNewCardTab(false);
                            setIsLoadCard(true);
                        }}
                    />
                </Sidebar>
            )}
            {uiState.isMobile && <ManageSubmit />}
        </div>
    );
};

export default FinalizeBooking;
