import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
    getBookingInfo,
    setBookingInitialState,
    setIsShowNoShowPolicy,
    setIsShowOrderSummary,
    setIsValidPaymentType,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setSelectedServices,
    setSelectedSpecialist,
    setStep,
    setTempStore,
} from "../Booking.slice";
import { EBookingTabState } from "../bookAppointment.interface";
import { axiosPatch, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { ISpecialistService } from "src/Interface";
import { toast } from "react-toastify";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/constants/routes";
import { allShopProducts, currentUser } from "src/redux/reducers/common/Common.slice";

const ManageSubmit = () => {
    const { t } = useTranslation();
    const bookingInfo = useAppSelector(getBookingInfo);
    const productList = useAppSelector(allShopProducts);
    const user = useAppSelector(currentUser);
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleStep = (step: EBookingTabState) => {
        dispatch(setStep(step));
    };

    const updateBooking = () => {
        setIsLoading(true);
        let url = API.BOOKING.UPDATE_PAY_NOW;

        const params = {
            id: bookingInfo.modifyingAppointmentId,
            shop_id: bookingInfo.selectedShopLocation?.shop_id,
            location_id: bookingInfo.selectedShopLocation?.id,
        };
        const payload: any = {
            booking_date: bookingInfo.selectedBookingDate,
            booking_time: bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
        };

        if (bookingInfo.oldPaymentOption === "pay_later") {
            url = API.BOOKING.UPDATE_PAY_LATER;

            payload.payment_type = bookingInfo.paymentType;
            payload.payment_option = bookingInfo.paymentOption;

            const products = bookingInfo.selectedProducts.map((product: any) => ({
                id: product.selectedVariant.inventory.id,
                quantity: 1,
            }));
            payload.products = products;

            const services = bookingInfo.selectedServices.map((service: any) => ({
                id: service.id,
                quantity: service.quantity,
            }));
            payload.services = services;
        }

        axiosPatch(url, payload, params)
            .then((response) => {
                dispatch(setBookingInitialState());
                toast.success(response.data.message);
                navigate(ROUTES.LOCATION);
            })
            .catch((error) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => setIsLoading(false));
    };

    const createBooking = () => {
        setIsLoading(true);
        const products = bookingInfo.selectedProducts.map((product: any) => ({
            id: product.selectedVariant.inventory.id,
            quantity: 1,
        }));

        const services = bookingInfo.selectedServices.map((service: any) => ({
            id: service.id,
            quantity: service.quantity,
        }));

        const params = {
            shop_id: bookingInfo.selectedShopLocation?.shop_id,
            location_id: bookingInfo.selectedShopLocation?.id,
        };

        const payload = {
            staff_id: bookingInfo.selectedSpecialist?.id,
            services,
            products,
            payment_type: bookingInfo.paymentType,
            payment_option: bookingInfo.paymentOption,
            booking_date: bookingInfo.selectedBookingDate,
            booking_time: bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
            shop_promotion_id: bookingInfo.selectedPromocode?.id ?? undefined,
        };
        axiosPost(API.BOOKING.CREATE, payload, params)
            .then(() => {
                dispatch(setBookingInitialState());
                navigate(ROUTES.LOCATION);
            })
            .catch((error) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => setIsLoading(false));
    };

    const handleChange = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.SELECT_DATE_TIME:
                dispatch(setSelectedBookingDate(bookingInfo.tempStore?.selectedBookingDate));
                dispatch(setSelectedBookingTime(bookingInfo.tempStore?.selectedBookingTime));
                dispatch(setTempStore({ selectedBookingDate: undefined, selectedBookingTime: undefined }));
                handleStep(EBookingTabState.TAB_MENU);
                break;
            case EBookingTabState.SELECT_SERVICE:
                dispatch(setSelectedServices(bookingInfo.tempStore?.selectedServices));
                dispatch(setTempStore({ selectedServices: [] }));
                handleStep(EBookingTabState.TAB_MENU);
                break;
            case EBookingTabState.SELECT_SPECIALIST:
                dispatch(setSelectedSpecialist(bookingInfo.tempStore?.selectedSpecialist));
                dispatch(setTempStore({ selectedSpecialist: undefined }));
                if (bookingInfo.selectedServices.length) {
                    const payload = {
                        staff_id: bookingInfo.tempStore?.selectedSpecialist?.id,
                        booking_date: bookingInfo.selectedBookingDate,
                        booking_time: bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
                    };
                    const params = {
                        shop_id: bookingInfo.selectedShopLocation?.shop_id,
                        location_id: bookingInfo.selectedShopLocation?.id,
                    };
                    axiosPost(API.SERVICE.LIST, payload, params)
                        .then((response) => {
                            const services = response.data.data;
                            const selectedServices: ISpecialistService[] = [];
                            bookingInfo.selectedServices.forEach((selectedService: ISpecialistService) => {
                                const service = services.find((item: ISpecialistService) => item.id === selectedService.id);
                                if (service) {
                                    selectedServices.push({ ...selectedService, price: service.price });
                                }
                            });
                            dispatch(setSelectedServices(selectedServices));
                        })
                        .catch((error) => toast.error(error?.message ?? "Something went wrong, Please try again"))
                        .finally(() => handleStep(EBookingTabState.TAB_MENU));
                } else {
                    handleStep(EBookingTabState.TAB_MENU);
                }
                break;
            default:
                break;
        }
    };

    const handleContinue = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.TAB_MENU:
                if (bookingInfo.isModifyingAppointment && bookingInfo.oldPaymentOption === "pay_now") {
                    updateBooking();
                } else {
                    let next = EBookingTabState.FINALIZE_BOOKING;
                    if (productList.length > 0) {
                        next = EBookingTabState.PRODUCT_BOOKING;
                    } else if (!user) {
                        dispatch(setIsShowLoginModal(true));
                        return;
                    }
                    dispatch(setStep(next));
                }
                break;
            case EBookingTabState.PRODUCT_BOOKING:
                if (!user) {
                    dispatch(setIsShowLoginModal(true));
                    return;
                }
                handleStep(EBookingTabState.FINALIZE_BOOKING);
                break;
            case EBookingTabState.FINALIZE_BOOKING:
                if (!bookingInfo.paymentType) {
                    dispatch(setIsValidPaymentType(true));
                } else if (bookingInfo.isModifyingAppointment) {
                    if (!bookingInfo.isShowNoShowPolicy) {
                        dispatch(setIsShowNoShowPolicy(true));
                    } else {
                        updateBooking();
                    }
                } else {
                    if (uiState.isMobile) {
                        if (!bookingInfo.isShowNoShowPolicy && !bookingInfo.isShowOrderSummary) {
                            dispatch(setIsShowNoShowPolicy(true));
                        } else if (bookingInfo.isShowNoShowPolicy) {
                            dispatch(setIsShowOrderSummary(true));
                            dispatch(setIsShowNoShowPolicy(false));
                        } else if (bookingInfo.isShowOrderSummary) {
                            createBooking();
                        }
                    } else {
                        if (!bookingInfo.isShowNoShowPolicy) {
                            dispatch(setIsShowNoShowPolicy(true));
                        } else {
                            createBooking();
                        }
                    }
                }
                break;
        }
    };

    const renderStepComponent = () => {
        switch (bookingInfo.step) {
            case EBookingTabState.TAB_MENU:
                if (bookingInfo.selectedShopLocation && bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime && bookingInfo.selectedServices.length && bookingInfo.selectedSpecialist) {
                    return (
                        <div className=" gap-2 absolute bottom-0 w-full bg-contentBackground lg:relative -tracking-[0.03rem] lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto ">
                            {/*  mx-auto items-center  justify-center mt-2.5 mb-5 lg:mb-0`} */}
                            <CustomButton
                                primary
                                disabled={isLoading}
                                isLoading={isLoading}
                                className={`${bookingInfo.isModifyingAppointment ? "w-1/2 !mx-0" : "w-full"} -tracking-[0.03rem]  mx-auto items-center  justify-center mt-2.5 mb-5 lg:mb-0`}
                                onClick={handleContinue(EBookingTabState.TAB_MENU)}
                            >
                                {bookingInfo.isModifyingAppointment ? t("Modify") : t("Continue")}
                            </CustomButton>
                            {bookingInfo.isModifyingAppointment && (
                                <CustomButton onClick={() => navigate(ROUTES.LOCATION)} className="border border-primary -tracking-[0.03rem] text-primary px-5 mt-2.5 h-[36px] w-1/2 mb-5 lg:mb-0">
                                    {t("Cancel")}
                                </CustomButton>
                            )}
                        </div>
                    );
                } else {
                    return <></>;
                }
            case EBookingTabState.SELECT_DATE_TIME:
                if (bookingInfo.selectedShopLocation && bookingInfo.tempStore?.selectedBookingDate && bookingInfo.tempStore?.selectedBookingTime) {
                    return (
                        <div className="absolute bottom-0 w-full bg-contentBackground lg:relative lg:bg-transparent gap-2 flex lg:gap-3 lg:items-center lg:!mx-auto ">
                            {/* lg:w-[420px] 2xl:w-[420px] 3xl:w-[520px] mx-auto items-center  justify-center mt-2.5 mb-5 lg:mb-0`} */}
                            <CustomButton
                                className={`${bookingInfo.isModifyingAppointment ? "w-[50%] !mx-0" : "w-full"} mx-auto -tracking-[0.03rem] items-center justify-center mt-2.5 mb-5 lg:mb-0`}
                                primary
                                onClick={handleChange(EBookingTabState.SELECT_DATE_TIME)}
                            >
                                {bookingInfo.isModifyingAppointment ? t("Modify") : t("Continue")}
                            </CustomButton>
                            {bookingInfo.isModifyingAppointment && (
                                <CustomButton
                                    onClick={() => handleStep(EBookingTabState.TAB_MENU)}
                                    className="border border-primary -tracking-[0.03rem] text-primary px-5 mt-2.5 h-[36px] w-1/2 mb-5 lg:mb-0"
                                >
                                    {t("Cancel")}
                                </CustomButton>
                            )}
                        </div>
                    );
                } else {
                    return <></>;
                }
            case EBookingTabState.SELECT_SERVICE:
                if (bookingInfo.selectedShopLocation && bookingInfo.tempStore?.selectedServices?.length > 0) {
                    return (
                        <div className="absolute bottom-0 w-full bg-contentBackground lg:relative lg:bg-transparent gap-2 flex lg:gap-3 lg:items-center lg:!mx-auto ">
                            {/* lg:w-[420px] 2xl:w-[420px] 3xl:w-[520px] mx-auto items-center  justify-center mt-2.5 mb-5 lg:mb-0`} */}

                            <CustomButton
                                className={`${bookingInfo.isModifyingAppointment ? "w-1/2 !mx-0" : "w-full "}   mx-auto items-center -tracking-[0.03rem] justify-center mt-2.5 mb-5 lg:mb-0`}
                                primary
                                onClick={handleChange(EBookingTabState.SELECT_SERVICE)}
                            >
                                {bookingInfo.isModifyingAppointment ? t("Modify") : t("Continue")}
                            </CustomButton>
                            {bookingInfo.isModifyingAppointment && (
                                <CustomButton
                                    onClick={() => handleStep(EBookingTabState.TAB_MENU)}
                                    className="border border-primary -tracking-[0.03rem] text-primary px-5 mt-2.5 h-[36px] w-1/2 mb-5 lg:mb-0"
                                >
                                    {t("Cancel")}
                                </CustomButton>
                            )}
                        </div>
                    );
                } else {
                    return <></>;
                }
            case EBookingTabState.SELECT_SPECIALIST:
                if (bookingInfo.selectedShopLocation && bookingInfo.tempStore?.selectedSpecialist) {
                    return (
                        <div className="bg-contentBackground lg:relative lg:bg-transparent">
                            <CustomButton
                                className="w-full lg:relative mx-auto  items-center -tracking-[0.03rem] justify-center mt-2.5 mb-5 lg:mb-0"
                                primary
                                onClick={handleChange(EBookingTabState.SELECT_SPECIALIST)}
                            >
                                {t("Continue")}
                            </CustomButton>
                        </div>
                    );
                } else {
                    return <></>;
                }
            case EBookingTabState.PRODUCT_BOOKING:
                if (bookingInfo.selectedShopLocation && bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime && bookingInfo.selectedServices.length) {
                    return (
                        <div className="absolute bottom-0 w-full bg-contentBackground lg:relative lg:bg-transparent gap-2 flex lg:gap-3 lg:items-center lg:!mx-auto ">
                            {/* lg:w-[420px] 2xl:w-[420px] 3xl:w-[520px] mx-auto items-center  justify-center mt-2.5 mb-5 lg:mb-0`} */}
                            <CustomButton
                                className={`${bookingInfo.isModifyingAppointment ? "w-1/2 !mx-0" : "w-full "}  -tracking-[0.03rem] mx-auto items-center  justify-center mt-2.5 mb-5 lg:mb-0`}
                                primary
                                onClick={handleContinue(EBookingTabState.PRODUCT_BOOKING)}
                            >
                                {bookingInfo.isModifyingAppointment ? t("Modify") : t("Continue")}
                            </CustomButton>
                            {bookingInfo.isModifyingAppointment && (
                                <CustomButton
                                    onClick={() => handleStep(EBookingTabState.TAB_MENU)}
                                    className="border border-primary -tracking-[0.03rem] text-primary px-5 mt-2.5 h-[36px] w-1/2 mb-5 lg:mb-0"
                                >
                                    {t("Cancel")}
                                </CustomButton>
                            )}
                        </div>
                    );
                } else {
                    return <></>;
                }
            case EBookingTabState.FINALIZE_BOOKING:
                if (bookingInfo.selectedShopLocation && bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime && bookingInfo.selectedServices.length && bookingInfo.selectedSpecialist) {
                    return (
                        <CustomButton
                            className="w-full mx-auto items-center tracking-[0.03rem] justify-center mt-2.5 mb-5 lg:mb-0 "
                            primary
                            onClick={handleContinue(EBookingTabState.FINALIZE_BOOKING)}
                            disabled={isLoading}
                            isLoading={isLoading}
                        >
                            {bookingInfo.isShowNoShowPolicy ? t("I Agree ") : t("Continue")}
                        </CustomButton>
                    );
                } else {
                    return <></>;
                }

            default:
                return <></>;
        }
    };

    return renderStepComponent();
};

export default ManageSubmit;
