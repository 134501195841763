import React, { useEffect, useState } from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import InputWithLabel from "../../../components/InputWithLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX, GENDER_OPTIONS, errorCode } from "../../../constants/common";
import { axiosPatch } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { convertBase64ToFile, getShortName, logout } from "src/utils/global-functions";
import { s3Upload } from "src/utils/s3Operations";
import { s3Path } from "src/utils/s3Path";
import { IRegularProfile } from "../Auth.interface";
import { BiInfoCircle } from "react-icons/bi";
import { Tooltip } from "flowbite-react";
import { PlaceholderImgPNG } from "src/theme/Images";
import CustomButton from "src/components/CustomButton";
import { currentShop, currentUser, me } from "src/redux/reducers/common/Common.slice";
import { Eye, EyeOff, ArrowNarrowLeft } from "@untitled-ui/icons-react/build/cjs";
import { userLogout } from "src/redux/reducers/Login.slice";

/* interface IGenderOptions {
    value: string;
    label: string;
} */

interface IProps {
    handleClose: () => void;
}

const RegularProfile = ({ handleClose }: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
    // const [customGenderOption, setCustomGenderOption] = useState<IGenderOptions[]>([]);
    const [referralConfig, setReferralConfig] = useState({ status: false, referee: 0, refferer: 0, referee_type: "percentage", refferer_type: "percentage" });
    const [errorImage, setErrorImage] = useState({
        profile: false,
    });

    const schema = Yup.object({
        first_name: Yup.string().required(t("This field is required")),
        last_name: Yup.string().required(t("This field is required")),
        password: Yup.string()
            .required(t("This field is required"))
            .min(6, t("Password must be at least 6 characters"))
            .trim()
            .matches(COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX, t("Include at least one uppercase, lowercase and either a digit or one of these special characters: !@#$%^&*_|")),
        password_confirmation: Yup.string()
            .required(t("This field is required"))
            .oneOf([Yup.ref("password"), null], t("Passwords do not match")),
        profile_image_name: Yup.object().nullable(),
        /* profile_image_name: Yup.object()
            .nullable()
            .shape({
                name: Yup.string().required(t("This field is required")),
                file: Yup.string().nullable(),
                url: Yup.string().required(t("This field is required")),
            })
            .test("image-validation", t("This field is required"), function (value) {
                if (!value) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t("This field is required"),
                    });
                }
                const { name, url } = value;
                if (!name || !url) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t("This field is required"),
                    });
                }
                return true;
            }), */
        // birthdate: Yup.string().required(t("This field is required")),
        // gender: Yup.string().required(t("Please select gender")),
        referral_code: Yup.string().notRequired(),
    }).required();

    const { handleSubmit, control, setError, setValue, watch } = useForm<IRegularProfile>({
        resolver: yupResolver(schema) as Resolver<IRegularProfile>,
        defaultValues: {
            first_name: user?.first_name || "",
            last_name: user?.last_name || "",
            password: "",
            password_confirmation: "",
            profile_image_name: null,
            // birthdate: "",
            // gender: user?.gender,
            referral_code: "",
        },
    });
    const watchProfileImage = watch("profile_image_name");
    const firstName = watch("first_name");
    const lastName = watch("last_name");

    useEffect(() => {
        const userGender = user?.gender ?? "";
        const foundOption = GENDER_OPTIONS.find((go) => go.value === userGender);
        if (!foundOption && userGender) {
            /* const customOption = {
                label: userGender,
                value: userGender,
            };
            setCustomGenderOption([...GENDER_OPTIONS, customOption]); */
        } else {
            // setCustomGenderOption(GENDER_OPTIONS);
        }
        // setValue("gender", userGender);
        const shopSetting = shop.shop_settings.find((shop_setting: { type: string }) => shop_setting.type === "refer");
        if (shopSetting) {
            setReferralConfig({
                status: shopSetting.status || false,
                referee: shopSetting.value.referee || 0,
                referee_type: shopSetting.value.referee_type || "percentage",
                refferer: shopSetting.value.refferer || 0,
                refferer_type: shopSetting.value.refferer_type || "percentage",
            });
        }
    }, []);

    const handleSave = async (payloadData: IRegularProfile) => {
        setIsLoading(true);

        try {
            let imageStatus = errorCode.updateSuccess;
            if (watchProfileImage?.file && watchProfileImage?.name) {
                const logoImageResponse: any = await s3Upload(watchProfileImage?.file, `${s3Path.USER_PROFILE}${watchProfileImage?.name}`);
                imageStatus = logoImageResponse?.status;
            }

            if (imageStatus === errorCode.updateSuccess) {
                const data = {
                    ...payloadData,
                    profile_image_name: watchProfileImage?.name,
                };
                // data.birthdate = format(parse(data.birthdate, "dd-MM-yyyy", new Date()), "Y-MM-dd");

                const response = await axiosPatch(API.USER.ACCOUNT, data, { shop_id: shop.id });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    await dispatch(me());
                    handleClose();
                    return;
                }

                if (response.data?.status === errorCode.unprocessable) {
                    Object.keys(response.data.data).forEach((field) => {
                        setError(field as any, {
                            type: "manual",
                            message: response.data.data[field][0],
                        });
                    });
                    return;
                }

                throw response.data;
            }
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const onChangeCrop = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const reader = new FileReader();
        reader.onloadstart = () => {};
        reader.onloadend = () => {
            const file = convertBase64ToFile(reader.result);
            if (file && file.filename && file.convertedFile) {
                setValue("profile_image_name", {
                    file: file.convertedFile as File,
                    url: reader.result as string,
                    name: file.filename,
                });
            }
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
    };

    const onErrorImage = (name: string) => () => {
        setErrorImage((old) => ({ ...old, [name]: true }));
    };

    const handleLogout = async () => {
        const result = await dispatch(userLogout({}));

        if (result.type === userLogout.fulfilled.toString()) {
            logout();
        }

        if (result.type === userLogout.rejected.toString()) {
            const response = result.payload.data;
            toast.error(response.message);
        }
    };

    return (
        <div className="content font-primary">
            <div className="mb-5">
                <h1 className="login-title-text">{t("Complete your profile")}</h1>
            </div>
            <form onSubmit={handleSubmit(handleSave)}>
                <div className={`flex flex-wrap justify-center -mx-4 overflow-y-scroll scrollbar-hide  h-[calc(100vh-368px)] `}>
                    <div className="px-4">
                        <div className="flex flex-wrap gap-4">
                            <Controller
                                name="profile_image_name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="w-full">
                                        <div className="relative flex gap-5 items-center">
                                            <input type="file" id="image" className=" absolute opacity-0 rounded-full h-[64px] w-[64px]" onChange={onChangeCrop} />

                                            <label htmlFor="image" className="relative h-[64px] w-[64px] rounded-full">
                                                {!errorImage.profile && value && value.url ? (
                                                    <img
                                                        src={value.url}
                                                        alt=""
                                                        onError={onErrorImage("profile")}
                                                        className="rounded-full bg-[#F2F4F7] flex justify-center items-center object-cover font-medium h-[64px] w-[64px] text-[32px]"
                                                    />
                                                ) : firstName && lastName ? (
                                                    <div className="rounded-full bg-[#F2F4F7] flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                                        <span className="text-[24px] leading-[36px] pt-1 font-medium">{getShortName(`${firstName} ${lastName}`)}</span>
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={PlaceholderImgPNG}
                                                        alt=""
                                                        className="rounded-full bg-[#F2F4F7] flex justify-center items-center object-cover font-medium h-[64px] w-[64px] text-[32px]"
                                                    />
                                                )}
                                            </label>
                                            <div className="flex flex-col">
                                                <div className="flex text-txtAppointmentColor text-base leading-[22.4px] font-medium mb-1 -tracking-[0.03rem]">Your profile picture</div>
                                                <label htmlFor="image" className="text-primaryAppointment text-sm cursor-pointer leading-[22.4px] font-medium -tracking-[0.03rem]">
                                                    Add a profile picture
                                                </label>
                                            </div>
                                        </div>
                                        {/* {value && value.url && (
                                            <ImageInfo className="w-[350px] h-[72px] mt-[6px] relative" name={value.name} url={value?.url} onRemove={handleRemoveImage} isApperance />
                                        )} */}

                                        {error && <p className="text-error">{error.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="w-full">
                                        <InputWithLabel label={t("First Name")} type="text" placeholder={t("Enter your first name")} handleChange={onChange} value={value} name="first_name" />
                                        {error && <p className="text-error !ml-0">{error.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="w-full">
                                        <InputWithLabel label={t("Last Name")} type="text" placeholder={t("Enter your last name")} handleChange={onChange} value={value} name="last_name" />
                                        {error && <p className="text-error !ml-0">{error.message}</p>}
                                    </div>
                                )}
                            />
                            {/* <Controller
                                name="gender"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="w-full">
                                        <SelectBox
                                            placeholder={t("Select your gender")}
                                            name="gender"
                                            label={t("Gender")}
                                            allowClear={false}
                                            options={customGenderOption}
                                            searchable={true}
                                            onChange={(option: IGenderOptions | undefined) => onChange(option?.value)}
                                            value={value && customGenderOption.find((option) => option.value === value)}

                                        />
                                        <p className="text-red-500">{errors.gender && errors.gender.message}</p>
                                    </div>
                                )}
                            /> */}
                            <Controller
                                name="password"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="w-full">
                                        <InputWithLabel
                                            label={t("Password")}
                                            buttonIconRight={
                                                isShowPassword ? (
                                                    <Eye width={16} color="#8c9cbb" className="cursor-pointer mr-3" />
                                                ) : (
                                                    <EyeOff width={16} color="#8c9cbb" className="cursor-pointer mr-3" />
                                                )
                                            }
                                            handlerIconClick={() => setIsShowPassword(!isShowPassword)}
                                            type={`${isShowPassword ? "text" : "password"}`}
                                            placeholder="•••••••• "
                                            handleChange={onChange}
                                            value={value}
                                            name="password"
                                        />
                                        {error && <p className="text-error !ml-0">{error.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name="password_confirmation"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="w-full">
                                        <InputWithLabel
                                            label={t("Confirm Password")}
                                            buttonIconRight={
                                                isShowConfirmPassword ? (
                                                    <Eye width={16} color="#8c9cbb" className="cursor-pointer mr-3" />
                                                ) : (
                                                    <EyeOff width={16} color="#8c9cbb" className="cursor-pointer mr-3" />
                                                )
                                            }
                                            handlerIconClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                                            type={`${isShowConfirmPassword ? "text" : "password"}`}
                                            placeholder="••••••••"
                                            handleChange={onChange}
                                            value={value}
                                            name="password_confirmation"
                                        />
                                        {error && <p className="text-error !ml-0">{error.message}</p>}
                                    </div>
                                )}
                            />
                            {/* <Controller
                                name="birthdate"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="w-full">
                                        <InputWithLabel
                                            label={t("Date of birth")}
                                            name="birthdate"
                                            inputClass="ba_form_control"
                                            placeholder={t("DD-MM-YYYY")}
                                            handleChange={(e) => {
                                                if ((e.nativeEvent as any)?.inputType === "deleteContentBackward") {
                                                    onChange(e.target.value);
                                                    return;
                                                }

                                                let input = e.target.value.replace(/\D/g, "");
                                                let formattedInput = "";

                                                if (input.length > 1) {
                                                    formattedInput += `${input.substring(0, 2)}-`;
                                                    input = input.substring(2);
                                                }

                                                if (input.length > 1) {
                                                    formattedInput += `${input.substring(0, 2)}-`;
                                                    input = input.substring(2);
                                                }

                                                formattedInput += input.substring(0, 4);

                                                e.target.value = formattedInput;
                                                onChange(formattedInput);
                                            }}
                                            buttonIcon={value ? <IoCloseCircle className="text-secondaryTxt text-xl" /> : null}
                                            handlerIconClick={() => onChange("")}
                                            value={value}

                                        />
                                        <p className="text-red-500">{errors.birthdate && errors.birthdate.message}</p>
                                    </div>
                                )}
                            /> */}
                            {referralConfig.status && (
                                <Controller
                                    name="referral_code"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className="mb-5 md:w-full">
                                            <div className="flex flex-row  mb-1.5 items-center">
                                                <span className="text-txtAppointmentColor text-sm font-normal leading-5">
                                                    Referral code <span className="text-textGrey">(Optional)</span>
                                                </span>
                                                <Tooltip
                                                    content={
                                                        <div>
                                                            <p className=" text-primaryAppointment text-xs font-normal">
                                                                {t("Receive 0% off any service when you refer a friend!", {
                                                                    value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                                                                })}
                                                            </p>
                                                        </div>
                                                    }
                                                    trigger="hover"
                                                    style={"light" as any}
                                                    className="w-[185px] bg-[#EDF1FD] p-3 rounded-lg "
                                                    placement="right"
                                                >
                                                    <BiInfoCircle className="text-primaryAppointment ml-1.5 cursor-pointer w-[16px]" />
                                                </Tooltip>
                                            </div>
                                            <InputWithLabel
                                                // label={t("Referral code (Optional)")}
                                                type="text"
                                                placeholder={t("Enter code")}
                                                handleChange={(e) => onChange(e.target.value.toUpperCase())}
                                                value={value ?? ""}
                                                name="referral_code"
                                            />
                                            {error && <p className="text-red-500">{error.message}</p>}
                                            <p className="text-secondaryTxt text-xs font-normal leading-4 -tracking-[0.03px] mt-2">
                                                {t("Enter a referral code to receive")}
                                                &nbsp;
                                                <span className="font-semibold">{referralConfig.referee_type === "percentage" ? `${referralConfig.referee}%` : `$${referralConfig.referee}`}</span>
                                                &nbsp;
                                                {t("your first appointment")}
                                            </p>
                                        </div>
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="border-t border-borderColorPrimary pb-3 pt-3">
                    <p className="text-txtcolor leading-[20px] -tracking-[0.03px] font-normal text-sm   text-justify ">
                        {t("By signing up, I agree to the")}&nbsp;
                        <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="text-primary">
                            {t("Terms of Service")}
                        </a>
                        &nbsp;{t("and")}&nbsp;
                        <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="text-primary">
                            {t("Privacy Policy")}
                        </a>
                        &nbsp;
                        {t("and I would like to be alerted for personalized offerings, discounts, and more.")}
                    </p>
                </div>
                <CustomButton primary type="submit" size="w-full" className="mt-5" isLoading={isLoading} disabled={isLoading}>
                    {uiState.isMobile ? t("Next") : t("Continue")}
                </CustomButton>
                <CustomButton
                    type="button"
                    icon={<ArrowNarrowLeft width={16} />}
                    className=" !border-0 ring-0  !bg-transparent text-center text-txtAppointmentColor w-full mt-2"
                    shadow={false}
                    onClick={handleLogout}
                >
                    {t("Back to Login")}
                </CustomButton>
            </form>
        </div>
    );
};

export default RegularProfile;
