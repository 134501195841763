import React, { useState } from "react";
import SpecialistDetail from "../../../components/SpecialistDetail";
import { PlaceholderImgPNG, CalenderSVG } from "src/theme/Images";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ServicesBasket from "./ServicesBasket";
import { AnimatePresence } from "framer-motion";
import { setIsShowSpecialistProfileModal, setShowSpecialistProfileModalData } from "../../../redux/reducers/Ui.slice";
import { format } from "date-fns";
import { EBookingTabState } from "../bookAppointment.interface";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ProductBasket from "./ProductBasket";
import BasketCalculation from "./BasketCalculation";
import { getBookingInfo, setSelectedPromocode } from "../Booking.slice";
import ManageSubmit from "./ManageSubmit";
import Sidebar from "src/components/Sidebar";
import Promotion from "./Promotion";
import { BiChevronDown } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { Calendar } from "@untitled-ui/icons-react/build/cjs";

const AppointmentPreview = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingInfo = useAppSelector(getBookingInfo);
    const uiStates = useAppSelector((data) => data.UiStates);
    const [isPromotionPopupOpen, setIsPromotionPopupOpen] = useState(false);

    const handlePromotion = () => {
        setIsPromotionPopupOpen(!isPromotionPopupOpen);
    };

    const handlePromotionRemove = (e: any) => {
        e.stopPropagation();
        dispatch(setSelectedPromocode(undefined));
    };

    return (
        <div className="lg:rounded-secondary lg:border-[1px] relative lg:border-borderColorPrimary  h-full scrollbar-hide bg-cardBackground">
            <img
                src={bookingInfo.selectedShopLocation?.location_image_url}
                className="rounded-secondary rounded-b-none w-full h-[120px] lg:h-[160px] object-cover"
                alt=""
                onError={(event) => (event.currentTarget.src = PlaceholderImgPNG)}
            />{" "}
            <div className=" lg:absolute w-full lg:w-[94%] lg:top-[77px] pt-4 p-2.5 lg:mx-4 bg-[#00000066] rounded-secondary mt-2 lg:mt-0">
                <h1 className="leading-[28px] text-lg lg:text-[12.8px] lg:leading-4 xl:text-base xl:leading-[22.4px] 2xl:text-lg 2xl:leading-[25.2px] font-medium  text-white -tracking-[0.03rem]">
                    {bookingInfo.selectedShopLocation?.name}
                </h1>
                <p className="mt-1 text-secondaryTxt text-[11px] font-normal lg:text-xs lg:leading-5 2xl:text-sm 2xl:leading-[18.2px]  md:text-sm md:leading-[140%] text-white -tracking-[0.03rem]">
                    {`${bookingInfo.selectedShopLocation?.street}, ${bookingInfo.selectedShopLocation?.city}, ${bookingInfo.selectedShopLocation?.country} ${bookingInfo.selectedShopLocation?.postal_code}`}
                </p>
                {/* <hr className="text-lightgray my-3" /> */}
            </div>
            <div className=" h-[calc(100vh-401px)] pb-[25px] lg:px-5 lg:pb-[25px] lg:h-[calc(100vh-(370px+64px))] overflow-auto scrollbar-hide">
                <p className="hidden lg:block mt-5 sm:text-lg sm:leading-[25.2px] lg:text-sm lg:leading-[18px] xl:text-base xl:leading-[22px] 2xl:text-lg 2xl:leading-[25.2px] font-medium text-txtcolor -tracking-[0.04rem]">
                    {t("Order Summary")}
                </p>
                <div className="flex flex-col gap-4">
                    <div>
                        {(bookingInfo.selectedSpecialist || bookingInfo.tempStore?.selectedSpecialist) && (
                            <div className="flex flex-col gap-3">
                                <h1 className="text-base leading-[22.4px] font-medium text-textGrey mt-5 -tracking-[0.03rem]">{t("Specialist")}</h1>
                                <div className="flex gap-2 items-center ">
                                    <div className="img">
                                        <img
                                            src={bookingInfo.tempStore?.selectedSpecialist?.profile_image_url ?? bookingInfo.selectedSpecialist?.profile_image_url ?? ""}
                                            className="w-6 h-6 rounded-primary"
                                            alt=""
                                            onError={(event) => (event.currentTarget.src = PlaceholderImgPNG)}
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-txtAppointmentColor text-sm leading-[18.2px] font-medium -tracking-[0.01rem]">
                                            {bookingInfo.tempStore?.selectedSpecialist?.full_name ?? bookingInfo.selectedSpecialist?.full_name}
                                        </p>
                                        {/* <p className="text-xs leading-[18px] text-textGrey -tracking-[0.01rem]">
                                            {bookingInfo?.tempStore?.selectedSpecialist?.staff_role?.name ?? bookingInfo?.selectedSpecialist?.staff_role?.name}
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        )}

                        {bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime && (
                            <div className="ms:px-4 lg:px-0 mt-4">
                                <div className="flex items-center ">
                                    <div className="flex flex-col gap-3">
                                        <h2 className="text-base leading-[22.4px] font-medium  text-textGrey -tracking-[0.03rem]">Date and Time</h2>
                                        <div className="flex gap-3 items-center">
                                            <div className="bg-dropdownBackgroundColor w-[25px] h-[25px] flex justify-center items-center rounded-primary text-[14px] text-textGrey">
                                                <Calendar className="w-5 h-5" />
                                            </div>
                                            <p className="text-txtAppointmentColor text-sm leading-[18.2px] font-medium -tracking-[0.03rem]">
                                                {moment(bookingInfo.selectedBookingDate).format("dddd MMMM Do - YYYY")}, at {moment(bookingInfo.selectedBookingTime, "HH:mm").format("h:mm a")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(bookingInfo.selectedServices || bookingInfo.tempStore.selectedServices) && (bookingInfo.selectedServices.length > 0 || bookingInfo.tempStore.selectedServices.length > 0) && (
                            <ServicesBasket />
                        )}
                        {bookingInfo.selectedProducts && bookingInfo.selectedProducts.length > 0 && <ProductBasket />}
                        {/* <div className="absolute bg-white bottom-10 w-full"> */}
                        {bookingInfo.step === EBookingTabState.FINALIZE_BOOKING && !bookingInfo.isModifyingAppointment && (
                            <div className="w-full mt-5">
                                <div className="text-txtAppointmentColor text-base mb-1.5">
                                    Add Promotion<span className="text-textGrey"> (Optional)</span>
                                </div>
                                <div
                                    className="w-full h-[36px] lg:h-[40px] border border-borderColorSecondary rounded-secondary text-txtAppointmentColor px-3.5 py-2.5 flex justify-between items-center cursor-pointer text-base lg:text-xs xl:text-sm  2xl:text-base"
                                    onClick={handlePromotion}
                                >
                                    {bookingInfo?.selectedPromocode?.name ? (
                                        <div className="text-secondaryTxt flex h-[24px] border border-borderColorSecondary rounded-secondary px-[8px] py-[3px] font-medium text-[12px]  items-center">
                                            <span className="text-txtAppointmentColor pt-[2px]">{bookingInfo?.selectedPromocode?.name ?? ""}</span>
                                            <IoClose className="w-4 h-4 ml-2 text-disableLightGreyText" onClick={handlePromotionRemove} />
                                        </div>
                                    ) : (
                                        "Select Promotion"
                                    )}

                                    <BiChevronDown className="text-textGrey w-5 h-5" />
                                </div>
                            </div>
                        )}

                        {bookingInfo.selectedSpecialist && bookingInfo.selectedBookingDate && bookingInfo.selectedServices && bookingInfo.selectedServices.length > 0 && <BasketCalculation />}
                        {/* </div> */}
                    </div>
                </div>

                {/* Show specialist detail component here */}
                {/* <AnimatePresence initial={false} onExitComplete={() => dispatch(setShowSpecialistProfileModalData(undefined))}>
                    {uiStates.isShowSpecialistProfileModal && (
                        <div className="absolute text-2xl bottom-0 w-full top-0 h-full pt-[80px] rounded-3xl bg-black bg-opacity-30">
                            <SpecialistDetail />
                        </div>
                    )}
                </AnimatePresence> */}
                {uiStates.isShowSpecialistProfileModal &&
                    (uiStates.isMobile ? (
                        <Sidebar
                            isOpen={uiStates.isShowSpecialistProfileModal}
                            position="bottom"
                            handleClose={() => {
                                dispatch(setIsShowSpecialistProfileModal(false));
                                dispatch(setShowSpecialistProfileModalData(undefined));
                            }}
                            isBottomView
                            customClass=""
                            size="w-[460px]"
                        >
                            <SpecialistDetail />
                        </Sidebar>
                    ) : null)}
                <Sidebar
                    isOpen={isPromotionPopupOpen}
                    handleClose={handlePromotion}
                    size="w-[460px]"
                    position={uiStates.isMobile ? "bottom" : "right"}
                    customClass=""
                    isBottomView={uiStates.isMobile ? true : false}
                >
                    {isPromotionPopupOpen && <Promotion handleClose={handlePromotion} />}
                </Sidebar>
            </div>
            <div className="absolute  left-[20px] right-[20px]">
                <ManageSubmit />
            </div>
        </div>
    );
};

export default AppointmentPreview;
