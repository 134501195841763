import React, { ReactNode, useEffect, useRef } from "react";
import { HiXMark } from "react-icons/hi2";

interface IDrawerProps {
    children: ReactNode;
    handleClose: () => void;
    isClosable?: boolean;
    dismissible?: boolean;
    size: string;
}

const DropdownModal = ({ children, isClosable = true, dismissible = true, handleClose, size }: IDrawerProps) => {
    const drawerRef = useRef(null);

    useEffect(() => {
        if (dismissible) {
            const handleClickOutside = (event: any) => {
                if (drawerRef.current && !(drawerRef.current as any).contains(event.target)) {
                    handleClose();
                }
            };
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [dismissible]);

    return (
        <main className="fixed overflow-hidden z-[9999] w-full bg-backdrop !m-0 p-0 inset-0 h-full ">
            <div
                className={` absolute right-[20px] bg-contentBackground z-[9999] md:right-[32px] top-[60px] shadow-[0px_5.3px_15.9px_0px_#928C9740] rounded-secondary min-h-[477px] w-[90%] md:w-full ${size}`}
                ref={drawerRef}
            >
                {isClosable && (
                    <button onClick={handleClose} className="absolute top-[27px] right-[24px] ">
                        <HiXMark size={24} className="text-[#000000]" />
                    </button>
                )}
                {children}
            </div>
        </main>
    );
};

export default DropdownModal;
