import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { IProps, ProductProps } from "./Product.interface";
import { onError } from "src/utils/global-functions";
import { PlaceholderImgPNG } from "src/theme/Images";

const ProductDetail: FC<IProps> = ({ data }) => {
    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 0, isShow: true });
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [selectedVariant, setSelectedVariant] = useState<any>(data.variants[0]);
    const [mainImage, setMainImage] = useState(PlaceholderImgPNG);

    useEffect(() => {
        const mainImages = data.images.find((image: any) => image.is_main);
        if (mainImages) {
            setMainImage(mainImages.image_url);
        }
    }, []);

    const handleImageClick = (url: string) => () => {
        setMainImage(url);
    };

    const ProductInfo: React.FC<ProductProps> = ({ index, title, description }) => (
        <div className="border-b border-borderColorPrimary py-5 text-txtAppointmentColor">
            <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsCollapsed({ isShow: !isCollapsed.isShow, index })}>
                <div className="flex-1">
                    <h3 className="text-sm font-bold uppercase -tracking-[0.01rem]">{title}</h3>
                </div>

                <div className="text-right -tracking-[0.01rem]">
                    <button className=" text-2xl">{isCollapsed.isShow && isCollapsed.index === index ? "−" : "+"}</button>
                </div>
            </div>
            {isCollapsed.isShow && isCollapsed.index === index && (
                <div className="mt-3">
                    <div className="flex justify-between items-center">
                        <div className="w-full">
                            <div className="flex space-x-2 font-normal text-sm text-txtAppointmentColor">{description}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <>
            {uiState.isMobile ? (
                <>
                    <img className="rounded-xl w-full h-[335px]" src={mainImage} alt="" onError={onError} />
                    <div className="flex flex-row">
                        <div className="flex-shrink-0 flex flex-row space-x-[16px] overflow-x-scroll scrollbar_add w-full max-w-[calc(100%-0px)]  py-3 mb-5 ">
                            {data.images?.map((image: any, index: number) => (
                                <img
                                    key={index}
                                    className="rounded-secondary object-cover h-[64px] w-[64px] cursor-pointer"
                                    src={image.image_url}
                                    alt=""
                                    onClick={handleImageClick(image.image_url)}
                                    onError={onError}
                                />
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex  bg-contentBackground gap-[16px] max-h-[328px] mb-[32px]">
                    <div className="flex-shrink-0 flex-col space-y-[10px] overflow-y-scroll h-full max-h-[328px] scrollbar-hide">
                        {data.images?.map((image: any, index: number) => (
                            <img
                                key={index}
                                className="w-[58px] h-[58px] object-cover rounded-secondary cursor-pointer"
                                src={image.image_url}
                                alt=""
                                onClick={handleImageClick(image.image_url)}
                                onError={onError}
                            />
                        ))}
                    </div>
                    <div className="flex-grow gap-[16px]">
                        <img src={mainImage} className="w-full max-w-[346px] h-[328px] rounded-secondary shadow-lg object-cover" alt="" onError={onError} />
                    </div>
                </div>
            )}
            <div className="flex gap-2 rounded-md shadow-shadowclr">
                <div className="w-full justify-between flex border-b border-borderColorPrimary ">
                    <div className="content">
                        <p className="text-sm font-normal text-primary -tracking-[0.03rem]">{data.name}</p>
                        <p className="font-medium text-[22px] text-txtAppointmentColor mt-2 -tracking-[0.03rem]">{data.name}</p>
                        <p className="font-medium text-[16px] text-txtAppointmentColor mt-2 mb-2 -tracking-[0.03rem]"> ${selectedVariant.inventory.price}</p>
                    </div>
                </div>
            </div>
            <div className="fl-tab-btn-view w-full py-5 items-center flex flex-wrap border-b border-borderColorPrimary">
                <div className="text-sm font-bold uppercase text-txtAppointmentColor mr-3 -tracking-[0.01rem]">{data.unit_of_measure === "size" ? "Size" : "Quantity"}</div>
                {data.variants?.map((variant: any) => (
                    <div
                        key={variant.id}
                        className={`fl-tab-link border-headerBorderColor text-textGrey cursor-pointer -tracking-[0.01rem] ${selectedVariant.id === variant.id ? "active" : ""}`}
                        onClick={() => {
                            setSelectedVariant(variant);
                        }}
                    >
                        {variant.size} {data.unit_of_measure}
                    </div>
                ))}
            </div>
            {selectedVariant?.color && (
                <div className="flex gap-4 items-center border-b border-line py-2 -tracking-[0.01rem]">
                    <div className="text-sm font-bold uppercase text-txtAppointmentColor ">Colors</div>
                    {selectedVariant.color}
                </div>
            )}
            <div className="-tracking-[0.03rem]">
                {data.details?.map((product: any, index: number) => (
                    <ProductInfo key={index} index={index} title={product.name} description={product.description} />
                ))}
            </div>
        </>
    );
};
export default ProductDetail;
