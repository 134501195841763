import React, { useEffect } from "react";
import { UserImagePNG } from "src/theme/Images";
import { FiChevronRight, FiX } from "react-icons/fi";
import { EBookingTabState } from "../bookAppointment.interface";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Specialist, DateTime, Services } from "../../../components/SvgColor/svgIcons";
import { setSelectedBookingDate, setSelectedServices, setSelectedSpecialist, setSelectedBookingTime, getBookingInfo, setStep } from "../Booking.slice";
import { onError } from "src/utils/global-functions";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";

const BookAppointmentTabMenu = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);

    useEffect(() => {
        const selectedDate = moment(bookingState.selectedBookingDate).startOf("day");
        const currentDate = moment().startOf("day");
        if (selectedDate.isBefore(currentDate)) {
            dispatch(setSelectedBookingDate(undefined));
            dispatch(setSelectedBookingTime(undefined));
        }
    }, []);

    const handleStaff = (event: any) => {
        event.stopPropagation();
        dispatch(setSelectedSpecialist(undefined));
        const updatedServices = bookingState.selectedServices.map((srvc) => ({
            ...srvc,
            price: undefined,
        }));
        dispatch(setSelectedServices(updatedServices));
    };

    const handleService = (id: number) => (event: any) => {
        event.stopPropagation();
        const services = bookingState.selectedServices.filter((_, index) => id !== index);
        dispatch(setSelectedServices(services));
    };

    const handleSelectDateAndTime = (event: any) => {
        event.stopPropagation();
        dispatch(setSelectedBookingDate(undefined));
        dispatch(setSelectedBookingTime(undefined));
    };

    const handleStep = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.SELECT_SPECIALIST:
                if (bookingState.isModifyingAppointment) {
                    return;
                }
                break;
            case EBookingTabState.SELECT_SERVICE:
                if (bookingState.isModifyingAppointment && bookingState?.oldPaymentOption === "pay_now") {
                    return;
                }
                break;
            default:
                break;
        }
        dispatch(setStep(step));
    };

    return (
        <>
            {/* <div className="title text-txtcolor font-bold text-xl md:text-[22px] xl:text-2xl lg:leading-[128%] -tracking-[1.325px] mt-14 xl:mt-16">
                {t("Book your next appointment at")} <span className="text-primary">{bookingState.selectedShopLocation?.name}</span>
            </div> */}
            <div className="mt-5 lg:mt-8 h-[calc(100vh-254px)] lg:h-[calc(100vh-228px)] pb-[40px] lg:pb-0 overflow-auto scrollbar-hide flex flex-col">
                <ul className="list-none p-0">
                    <li onClick={handleStep(EBookingTabState.SELECT_SPECIALIST)}>
                        <div className="bg-cardBackground border border-borderColorPrimary p-3 md:p-4 xl:p-5 flex gap-5 items-center justify-between rounded-secondary relative cursor-pointer shadow-none hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] transition-all  ">
                            {/* demo  */}
                            {/* <div className="flex gap-3 items-center">
                                <div className="img">
                                    <img
                                        src={UserImagePNG}
                                        className="w-10 h-10 rounded-[10px]"
                                        alt=""
                                        onError={(event) => (event.currentTarget.src = PlaceholderImg)}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <h2 className="text-txtAppointmentColor text-sm leading-[18.2px] lg:text-[10px] lg:leading-3 xl:text-xs 2xl:text-sm 2xl:leading-[140%] font-medium   ">
                                        {`${bookingState.selectedSpecialist.first_name} ${bookingState.selectedSpecialist.last_name}`}
                                    </h2>
                                    <p className="text-xs leading-[18px] lg:text-[10px] lg:leading-3 xl:text-[11px] xl:leading-4 2xl:text-xs 2xl:leading-[18px]  mt-1 font-normal  text-textGrey">
                                        {bookingState.selectedSpecialist?.role?.name}
                                    </p>
                                </div>
                            </div>
                            <div
                                onClick={(event) => {
                                    event.stopPropagation();
                                    dispatch(setSelectedSpecialist(undefined));
                                    const updatedServices = bookingState.selectedServices.map((srvc) => ({
                                        ...srvc,
                                        price: undefined,
                                    }));
                                    dispatch(setSelectedServices(updatedServices));
                                }}
                            >
                                <FiX className="text-textGrey text-lg" />
                            </div> */}

                            {bookingState.selectedSpecialist ? (
                                <>
                                    <div className="flex gap-3 items-center">
                                        <div className="img">
                                            <img src={bookingState?.selectedSpecialist?.profile_image_url ?? UserImagePNG} className="w-11 h-11 rounded-primary  " alt="" onError={onError} />
                                        </div>
                                        <div className="flex flex-col">
                                            <h2 className="text-base !leading-5  xl:text-lg xl:leading-[140%] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">
                                                {bookingState.selectedSpecialist.full_name}
                                            </h2>
                                            <p className="text-sm font-medium text-textGrey -tracking-[0.01rem] leading-[150%]">{bookingState.selectedSpecialist?.staff_role?.name}</p>
                                        </div>
                                    </div>
                                    {!bookingState.isModifyingAppointment && (
                                        <div onClick={handleStaff} className="min-w-6">
                                            <FiX className="text-textGrey text-lg" />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="flex gap-3 items-center">
                                    <div className="flex flex-row items-center gap-4">
                                        {/* <div className="p-[10px] lg:p-[8px] xl:p-[9px] 2xl:p-[10px] border border-primary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center ">
                                            <Specialist fillColor="var(--brand-color)" />
                                        </div> */}
                                        <div className="">
                                            <h2 className="text-base !leading-5  xl:text-lg xl:leading-[140%] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">
                                                {t("Select a specialist")}
                                            </h2>
                                            <p className="text-sm font-medium text-textGrey -tracking-[0.01rem] leading-[150%]">4 Professionals</p>
                                        </div>
                                    </div>
                                    <div className="absolute -translate-y-1/2  top-1/2 right-4 lg:right-6">
                                        <FiChevronRight className="text-secondaryTxt text-2xl" />
                                    </div>
                                </div>
                            )}
                        </div>
                    </li>
                    <li className="mt-3" onClick={handleStep(EBookingTabState.SELECT_SERVICE)}>
                        {bookingState.selectedServices && bookingState.selectedServices.length ? (
                            <div className="bg-cardBackground border border-borderColorPrimary p-3 md:p-4 xl:p-5 flex flex-col items-center justify-between rounded-secondary relative cursor-pointer shadow-none hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] transition-all ">
                                <div className="flex justify-between items-center w-full">
                                    <div className="flex flex-col flex-1 relative">
                                        <h2 className="text-base !leading-5  xl:text-lg xl:leading-5 text-txtcolor font-semibold align-middle -tracking-[0.03rem]">{t("Services")}</h2>
                                        <p className="text-sm font-medium text-textGrey -tracking-[0.01rem] leading-[150%]">
                                            {bookingState.selectedServices.length} {t("selected")}
                                        </p>
                                    </div>
                                    <div>
                                        <FiChevronRight className="text-secondaryTxt text-xl" />
                                    </div>
                                </div>

                                {bookingState.selectedServices.map((selectedService, index) => (
                                    <div className="flex justify-between border-t mt-4 pt-4 border-borderColorPrimary w-full items-center" key={`${index}_${selectedService.name}`}>
                                        <div className="flex gap-3 items-center">
                                            <p className="text-txtAppointmentColor text-sm leading-[18.2px] lg:text-[10px] lg:leading-3 xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium flex items-center justify-center  h-[24px] w-[24px]  bg-dropdownBackgroundColor rounded-primary text-[14px]">
                                                <span className="pt-[2px]">{selectedService.quantity}</span>
                                            </p>
                                            <p className="text-txtAppointmentColor text-sm leading-[18.2px] font-medium -tracking-[0.03rem]">{selectedService.name}</p>
                                        </div>
                                        {bookingState.isModifyingAppointment && bookingState?.oldPaymentOption === "pay_now" ? (
                                            <></>
                                        ) : (
                                            <div onClick={handleService(index)}>
                                                <FiX className="text-secondaryTxt text-lg" />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="bg-cardBackground border border-borderColorPrimary p-3 md:p-4 xl:p-5 flex gap-4 items-center rounded-secondary relative cursor-pointer shadow-none hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] transition-all ">
                                {/* <div className="p-[10px] lg:p-[8px] xl:p-[9px] 2xl:p-[10px] border border-primary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center">
                                    <Services fillColor="var(--brand-color)" />
                                </div> */}
                                <div className="">
                                    <h2 className="text-base !leading-5  xl:text-lg xl:leading-[140%] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">{t("Select a service")}</h2>
                                    <p className="text-sm font-medium text-textGrey -tracking-[0.01rem] leading-[150%]">10 Options</p>
                                </div>
                                <div className="absolute -translate-y-1/2  top-1/2 right-4 lg:right-6">
                                    <FiChevronRight className="text-secondaryTxt text-2xl" />
                                </div>
                            </div>
                        )}
                    </li>
                    <li className="mt-3" onClick={handleStep(EBookingTabState.SELECT_DATE_TIME)}>
                        {bookingState.selectedBookingTime && bookingState.selectedBookingDate ? (
                            <div className="bg-cardBackground border border-borderColorPrimary  p-3 md:p-4 xl:p-5 rounded-secondary relative cursor-pointer lg:max-h-[220px] lg:overflow-hidden 2xl:max-h-full flex justify-between items-center shadow-none hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] transition-all">
                                <div className="flex gap-4 items-center">
                                    <div className="w-11 h-11 bg-dropdownBackgroundColor  rounded-primary flex flex-col justify-center items-center">
                                        <p className="text-base leading-[22.4px] font-medium   text-txtAppointmentColor">{moment(bookingState.selectedBookingDate).format("DD")}</p>
                                        <p className="text-xs !leading-3 text-textGrey">{moment(bookingState.selectedBookingDate).format("ddd")}</p>
                                    </div>
                                    <div className="left">
                                        <h2 className="text-base !leading-5  xl:text-lg xl:leading-5 text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">
                                            {moment(bookingState.selectedBookingDate).format("dddd, MMMM Do, YYYY")}
                                        </h2>
                                        <p className="text-sm font-medium text-textGrey -tracking-[0.01rem] leading-[150%]">
                                            {format(new Date(`2000-01-01T${bookingState.selectedBookingTime}`), "hh:mm a")}
                                        </p>
                                    </div>
                                </div>
                                <div onClick={handleSelectDateAndTime}>
                                    <FiX className="text-textGrey text-lg" />
                                </div>
                            </div>
                        ) : (
                            <div className="bg-cardBackground border border-borderColorPrimary p-3 md:p-4 xl:p-5 flex gap-5 items-center justify-between rounded-secondary relative cursor-pointer shadow-none hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] transition-all">
                                <div className="flex gap-3 items-center">
                                    {/* <div className="p-[10px] lg:p-[8px] xl:p-[9px] 2xl:p-[10px] border border-primary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center">
                                        <DateTime fillColor="var(--brand-color)" />
                                    </div> */}
                                    <div className="">
                                        <h2 className="text-base !leading-5  xl:text-lg xl:leading-[140%] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">
                                            {t("Select a date and time")}
                                        </h2>
                                        <p className="text-sm font-medium text-textGrey -tracking-[0.01rem] leading-[150%]">
                                            Next availability <span className="text-primary text-sm ">Tomorrow</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="absolute -translate-y-1/2  top-1/2 right-4 lg:right-6">
                                    <FiChevronRight className="text-secondaryTxt text-2xl" />
                                </div>
                            </div>
                        )}
                    </li>
                </ul>
            </div>
            {uiState.isMobile && <ManageSubmit />}
        </>
    );
};

export default BookAppointmentTabMenu;
