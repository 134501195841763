import React, { useEffect, useState } from "react";
import { GoInfo } from "react-icons/go";
import { Tooltip } from "flowbite-react";
import { useAppSelector } from "../../../redux/hooks";
import { InfoCircle } from "@untitled-ui/icons-react/build/cjs";

const ProductCard = ({ product, onClick, handleAction, isSelected = false }: any) => {
    const uiState = useAppSelector((data) => data.UiStates);
    const [hoverProduct, setHoverProduct] = useState<any>(null);
    const [defaultPrice, setDefaultPrice] = useState<number | null>(null);

    useEffect(() => {
        if (product.variants && product.variants.length > 0) {
            setDefaultPrice(product.variants[0].inventory.price); // Set default price to the first variant's price
        }
    }, [product]);

    const handleMouseEnter = (variantDetails: any) => () => {
        setHoverProduct(variantDetails);
    };

    const handleMouseLeave = () => {
        setHoverProduct(null);
    };

    return (
        <div className="w-full flex flex-col">
            <div className={`border p-3 rounded-secondary h-full bg-productBackgroundColor ${isSelected ? "border-primary" : "border-borderColorPrimary hover:border-primary hover:shadow-md"}`}>
                <div className="cursor-pointer relative" onClick={handleAction("product", product)}>
                    <img
                        // className="w-full rounded-[12px] h-[118px] sm:h-[106px] md:h-[139px] lg:h-[80px] xl:h-[110px] 2xl:h-[138px] 3xl:h-[200px]  object-cover"
                        className="w-full rounded-[12px] aspect-square  object-cover"
                        src={product?.images?.find((image: any) => image?.is_main)?.image_url}
                        alt=""
                    />
                    {(hoverProduct && product.id === hoverProduct.shop_product_id) || defaultPrice ? (
                        <div className=" absolute right-[6px] bottom-[6px]">
                            <div className="bg-black text-white rounded-full px-3 py-1 text-xs font-medium -tracking-[0.01rem]">${hoverProduct ? hoverProduct.price : defaultPrice}</div>
                        </div>
                    ) : null}
                </div>

                <div className="flex flex-col w-full mt-3">
                    <div className="flex gap-1 mb-1 ">
                        <h2 className="text-sm font-medium text-txtcolor leading-[140%] -tracking-[0.01rem] flex-auto truncate">{product.name}</h2>
                        {uiState.isMobile ? (
                            <div className="cursor-pointer items-end" onClick={handleAction("product", product)}>
                                <InfoCircle color="#8C9CBB" />
                            </div>
                        ) : (
                            <Tooltip
                                content={
                                    <div>
                                        <p className="text-txtcolor -tracking-[0.01rem] mb-1">{product.name}</p>
                                        <p className="text-secondaryTxt font-medium text-xs -tracking-[0.01rem]">{product.description}</p>
                                    </div>
                                }
                                trigger="hover"
                                style={"light" as any}
                                className="w-64 bg-contentBackground !rounded-secondary tracking-[0.03rem]"
                                placement="bottom"
                            >
                                <InfoCircle id="alert_circle_svg" width={16} className="text-primary" onClick={handleAction("product", product)} />
                            </Tooltip>
                        )}
                    </div>
                    <div className="flex items-center gap-[6px] -tracking-[0.03rem] overflow-auto no-scrollbar">
                        {product.selectedVariant ? (
                            <div className="variant  cursor-pointer active_add relative" onClick={onClick(product, product.selectedVariant)}>
                                <span className="variant_text_title whitespace-nowrap">
                                    {product.selectedVariant.size} {product.unit_of_measure}
                                </span>

                                {/* <span className="variant_border  active_add"></span> */}
                                {/* <p className="variant_text_price">${product.selectedVariant.inventory.price}</p> */}
                                {/* <MinusCircle className="w-4 absolute h-4 top-[-8px] right-[-8px] bg-contentBackground rounded-full" /> */}
                            </div>
                        ) : (
                            product.variants.map((variant: any) => (
                                <div
                                    className={`variant group hover:border-primary cursor-pointer relative ${!hoverProduct && defaultPrice === variant.inventory.price ? "border-primary" : ""}`}
                                    key={variant.id}
                                    onClick={onClick(product, variant)}
                                    onMouseOver={handleMouseEnter(variant)}
                                    onMouseOut={handleMouseLeave}
                                    onFocus={handleMouseEnter(variant)}
                                    onBlur={handleMouseLeave}
                                >
                                    <span className="variant_text_title whitespace-nowrap">
                                        {variant.size} {product.unit_of_measure}
                                    </span>
                                    {/* <span className="variant_border group-hover:border-primary"></span> */}
                                    {/* <p className="variant_text_price">${variant.inventory.price}</p> */}
                                    {/* <PlusCircle className="w-4 absolute h-4 top-[-8px] right-[-8px] bg-contentBackground rounded-full group-hover:border-primary" /> */}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
