import React, { useEffect } from "react";
import AppointmentPreview from "./AppointmentPreview";
import { EBookingTabState } from "./bookAppointment.interface";
import SelectSpecialists from "./SelectSpecialists";
import SelectDateAndTime from "./SelectDateAndTime";
import SelectServices from "./SelectServices";
import BookAppointmentTabMenu from "./BookAppointmentTabMenu";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FinalizeBooking from "./FinalizeBooking";
import ProductBooking from "./ProductBooking";
import Stepper from "./Stepper";
import { getBookingInfo, setStep } from "src/app/BookAppointment/Booking.slice";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/constants/routes";
import { allShopProducts, currentShop, getAllShopProducts } from "src/redux/reducers/common/Common.slice";

const BookAppointment = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const productList = useAppSelector(allShopProducts);
    const booking = useAppSelector(getBookingInfo);
    const shop = useAppSelector(currentShop);

    useEffect(() => {
        if (!booking.selectedShopLocation) {
            navigate(ROUTES.LOCATION);
        }
        handleStep(EBookingTabState.TAB_MENU);

        listProducts();
    }, []);

    const listProducts = () => {
        const payload = {
            params: {
                shop_id: shop.id,
                location_id: booking.selectedShopLocation?.id,
            },
            data: {
                booking_id: booking.modifyingAppointmentId || null,
            },
        };
        dispatch(getAllShopProducts(payload));
    };

    const handleStep = (step: EBookingTabState) => {
        dispatch(setStep(step));
    };

    return (
        // <div className="w-full flex flex-col relative h-full lg:h-auto">
        <div className="p-8 bg-[#f8f8f8]">
            <div className="rounded-3xl bg-white shadow-md p-8 h-[calc(100vh-(52px+64px))] lg:h-[calc(100vh-(60px+64px))] text-secondary-appointment font-primary flex flex-col  lg:flex-row flex-1 scrollbar-hide md:overflow-hidden">
                <div className="lg:w-[52%] xl:w-[60%] 2xl:flex-1 flex flex-col relative h-full pr-0 lg:pr-8">
                    <div className="flex flex-col flex-1 relative max-h-[calc(100%-36px)] overflow-hidden">
                        {!(uiState.isMobile && (booking.step === EBookingTabState.SELECT_DATE_TIME || booking.step === EBookingTabState.SELECT_SERVICE)) && (
                            <div>
                                <Stepper />
                            </div>
                        )}
                        <div className="p-2.5 bg-dropdownBackgroundColor rounded-secondary lg:hidden mt-4">
                            <h1 className="leading-[18.2px] text-sm font-[500] text-txtcolor">{booking.selectedShopLocation?.name}</h1>
                            <p className="mt-1 leading-[18.2px] text-sm text-secondaryTxt font-normal">
                                {`${booking.selectedShopLocation?.street}, ${booking.selectedShopLocation?.city}, ${booking.selectedShopLocation?.country} ${booking.selectedShopLocation?.postal_code}`}
                            </p>
                        </div>
                        {booking.step === EBookingTabState.TAB_MENU && <BookAppointmentTabMenu />}
                        {booking.step === EBookingTabState.SELECT_SPECIALIST && <SelectSpecialists />}
                        {booking.step === EBookingTabState.SELECT_SERVICE && <SelectServices />}
                        {booking.step === EBookingTabState.PRODUCT_BOOKING && <ProductBooking />}
                        {booking.step === EBookingTabState.SELECT_DATE_TIME && <SelectDateAndTime />}
                        {booking.step === EBookingTabState.FINALIZE_BOOKING && (
                            <FinalizeBooking
                                handleBackToBooking={() =>
                                    handleStep(
                                        productList.length > 0
                                            ? booking.isModifyingAppointment && booking.oldPaymentOption === "pay_now"
                                                ? EBookingTabState.TAB_MENU
                                                : EBookingTabState.PRODUCT_BOOKING
                                            : EBookingTabState.TAB_MENU,
                                    )
                                }
                            />
                        )}
                    </div>
                    <div className="w-full py-2 hidden lg:block bg-contentBackground z-10">
                        <p className="text-sm leading-[19.6px] font-normal -tracking-[0.03rem] text-secondaryTxt">
                            powered by <span className="text-primaryAppointment">getflair.ca</span>
                        </p>
                    </div>
                </div>
                <div className="hidden relative lg:block h-full lg:flex-1 2xl:flex-none overflow-hidden px-6 md:px-[30px] lg:px-0 lg:w-[400px] xl:w-[480px] 2xl:w-[480px] 3xl:w-[550px]">
                    <AppointmentPreview />
                </div>
            </div>
        </div>
        // </div>
    );
};

export default BookAppointment;
