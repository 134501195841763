import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { DefaultUserImagePNG, UnionSVG } from "src/theme/Images";
import { useTranslation } from "react-i18next";
import { ISelectedTab } from "../../../../Interface";
import { selectTab, setIsScrollable } from "../../../../redux/reducers/Landing.slice";
import Profile from "../../../Profile";
import { useLocation } from "react-router-dom";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import { IInitAction, initAction, navLinks } from "./Header.interface";
import CompleteProfile from "src/app/Auth/CompleteProfile/CompleteProfile";
import Auth from "src/app/Auth";
import { ROUTES } from "src/constants/routes";
import { currentSettings, currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { Menu02, XClose } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";
import SelectBox from "src/components/SelectBox";

const Header: React.FC<any> = () => {
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const activeTab = useAppSelector((data) => data.LandingStates.initSelectedTab);
    const settings = useAppSelector(currentSettings);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [action, setAction] = useState<IInitAction>(initAction);
    const [nav, setNav] = useState(false);
    const [template, setTemplate] = useState<string[]>([]);
    const [appearance, setAppearance] = useState<any>(null);
    const [language, setLanguage] = useState("eng");

    const languageOptions = [
        { label: "ENG", value: "eng" },
        { label: "FRA", value: "fra" },
    ];

    useEffect(() => {
        if (settings.length) {
            const templates = settings.find((setting: any) => setting.type === "template");
            if (templates) {
                setTemplate(extractKeysWithStatusTrue(templates.value));
            }

            const appearances = settings.find((setting: any) => setting.type === "appearance");
            if (appearances) {
                setAppearance(appearances.value);
            }
        }
    }, [settings]);

    useEffect(() => {
        if (user) {
            const isAccountComplete = user.is_account_complete;
            if (!isAccountComplete) {
                handleAction("completeProfile")();
            }
        }
    }, [user]);

    const extractKeysWithStatusTrue = (obj: any) => {
        const result = [];
        for (const key in obj) {
            if (obj[key].status === true) {
                result.push(key);
            }
        }
        return result;
    };

    const handleNavigate = (tabName: ISelectedTab) => () => {
        dispatch(setIsScrollable(true));
        dispatch(selectTab(tabName));
        location.pathname !== ROUTES.HOME && navigate(ROUTES.HOME, { state: { selectTab: tabName, isScrollable: true } });
    };

    const handleAction = (type: keyof IInitAction) => () => {
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleLogin = () => {
        handleNav();
        dispatch(setIsShowLoginModal(true));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    const handleNav = () => {
        setNav(!nav);
    };

    const getLogo = () => {
        let response;
        let logo;
        let text;

        if (shop) {
            logo = <img src={shop.logo_image_url ?? UnionSVG} className="max-w-[120px] h-[46px] lg:h-[50px] me-2 object-contain" alt="logo" />;
            text = (
                <p className={` text-txtcolor hover:text-primary cursor-pointer text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] flex items-center font-logo font-logoWeight`}>
                    {shop.business_name}
                </p>
            );

            if (appearance) {
                switch (appearance.logo_type) {
                    case "logo":
                        response = logo;
                        break;
                    case "logo_text":
                        response = (
                            <>
                                {logo} {text}
                            </>
                        );
                        break;
                    case "text":
                        response = text;
                        break;
                    default:
                        break;
                }
            }
        }

        return response;
    };

    const handleLanguage = (selectedOption: any) => {
        setLanguage(selectedOption.value);
    };

    return (
        <div
            className={`flex justify-between border-b border-borderColorPrimary items-center px-5 ${
                location.pathname === "/appointment" ? "lg:px-8" : "md:px-[32px]"
            }  h-[52px] lg:h-[60px] w-full z-[9999] bg-contentBackground top-0 sticky `}
        >
            <div className="items-center cursor-pointer justify-between  w-full md:w-auto flex">
                <div className="flex items-center" onClick={() => navigate("/")}>
                    {getLogo()}
                </div>
                <div className="w-[35px] justify-end pl-2.5 ml-3 border-l md:hidden border-borderColorPrimary" onClick={handleNav}>
                    {nav ? <XClose color="var(--brand-color)" className="w-[24px]" /> : <Menu02 className="w-[24px]" color="var(--brand-color)" />}
                </div>
            </div>
            <ul
                className={
                    nav
                        ? `ease-in-out right-0 z-[99999] absolute h-[100vh] top-[48px] bg-contentBackground flex flex-col md:flex-row md:justify-between md:items-center md:h-[56px] 2xl:h-[56px] md:space-x-3 lg:space-x-6 md:top-0 xl:h-[56px] xl:space-x-7 duration-500 md:sticky pl-[12px] pt-[12px] md:pl-[0px] md:pt-[0px] ${"min-w-[240px]"} justify-start navbar-duration items-start`
                        : " space-x-3 md:space-x-3 lg:space-x-6 xl:space-x-7 navbar-duration ease-in-out duration-500 hidden md:flex items-center"
                }
            >
                {navLinks
                    .filter(({ tabName }) => template.includes(tabName))
                    .map(({ name, tabName }) => (
                        <li
                            key={tabName}
                            className={`hover:text-primary font-normal cursor-pointer mt-[7px] text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] uppercase ${
                                activeTab[tabName] ? "text-primary" : "text-txtcolor"
                            }`}
                            onClick={handleNavigate(tabName)}
                        >
                            <span onClick={handleNav}>{name}</span>
                        </li>
                    ))}
                <SelectBox
                    options={languageOptions}
                    value={languageOptions.find((option) => option.value === language)}
                    onChange={handleLanguage}
                    inputClass="outline-select-box"
                    allowClear={false}
                    searchable={false}
                    disabled={false}
                    name={"language"}
                />
                {/* <li className="hover:text-primary font-normal cursor-pointer mt-[7px] text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] text-txtcolor uppercase">
                    <span onClick={handleNav}>(ENG / FRA)</span>
                </li> */}
                <CustomButton primary size="w-full" className="bg-primary text-primaryText px-8 py-3 h-[42px]" onClick={() => navigate(ROUTES.LOCATION)}>
                    Book Now
                </CustomButton>
                {user ? (
                    <div className="flex gap-2">
                        <div onClick={handleAction("profile")} className="flex cursor-pointer items-center rounded-primary w-9 h-9">
                            <img src={user?.profile_image_url ?? DefaultUserImagePNG} className="w-full h-full rounded-primary" alt="" />
                        </div>
                    </div>
                ) : (
                    <CustomButton
                        secondary
                        size="w-full"
                        className="hover:text-primary font-normal cursor-pointer mt-[7px] text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] text-txtcolor uppercase"
                        onClick={handleLogin}
                    >
                        {t("Login")}
                    </CustomButton>
                    // <button
                    //     className="hover:text-primary font-normal cursor-pointer mt-[7px] text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] text-txtcolor uppercase"
                    //     onClick={handleLogin}
                    // >
                    //     <span onClick={handleNav} className="`">
                    //         {t("Login")}
                    //     </span>
                    // </button>
                )}
                {AuthUserState.isShowLoginModal && <Auth />}
                <Profile handleClose={handleClose} isOpen={action.profile} />
                {action.completeProfile && <CompleteProfile handleClose={handleClose} />}
            </ul>
        </div>
    );
};

export default Header;
