import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import BookingCard from "./BookingCard";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";
import Sidebar from "src/components/Sidebar";
import BookingView from "./BookingView";
import CustomButton from "src/components/CustomButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/constants/routes";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { initAction } from "../Profile.interface";
import Tip from "./Tip";

interface IProps {
    handleClose: () => void;
}

const BookingList = ({ handleClose }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((data) => data.UiStates);
    const [action, setAction] = useState(initAction);
    const [isRefresh, setIsRefresh] = useState(true);
    const [booking, setBooking] = useState<IAppointmentBooking | null>(null);
    const [isLoading, setIsLoading] = useState({
        upcoming: true,
        past: true,
    });
    const [bookings, setBookings] = useState({
        upcoming: [],
        past: [],
    });

    useEffect(() => {
        if (isRefresh) {
            listBookings("past");
            listBookings("upcoming");
        }
    }, [isRefresh]);

    const listBookings = (type: string) => {
        setIsLoading((old) => ({ ...old, [type]: true }));
        const payloadData = {
            skip: 0,
            type,
        };
        const params = { shop_id: shop.id };
        axiosPost(API.BOOKING.LIST, payloadData, params)
            .then((response) => {
                setBookings((old) => ({ ...old, [type]: response.data.data }));
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsLoading((old) => ({ ...old, [type]: false }));
                setIsRefresh(false);
            });
    };

    const navigateLocation = () => {
        handleClose();
        navigate(ROUTES.LOCATION);
    };

    const handleAction = (type: string, data: IAppointmentBooking) => (event: any) => {
        event.stopPropagation();
        setBooking(data);
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleActionClose = () => {
        setAction(initAction);
    };

    return (
        <>
            <div className="flex flex-col mb-6">
                <h3 className="text-[20px] mb-1.5 leading-[28px] font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">{t("Appointment History")}</h3>
                <span className="font-normal text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">{t("Manage when you’ll receive Appointments")}</span>
            </div>
            <div className="mb-6 flex flex-col">
                <div className="flex justify-between items-center mb-2">
                    <h2 className="text-sm font-bold text-txtAppointmentColor leading-[18.2px]">{t("Upcoming Appointments")}</h2>
                    <button className="text-sm font-medium leading-[18.2px] -tracking-[0.01em] text-primaryAppointment" onClick={navigateLocation}>
                        + {t("New appointment")}
                    </button>
                </div>
                {bookings.upcoming.length > 0 ? (
                    bookings.upcoming.map((upcomingBooking: any) => (
                        <div key={`upcoming - ${upcomingBooking.id}`} className="cursor-pointer" onClick={handleAction("booking", upcomingBooking)}>
                            <BookingCard booking={upcomingBooking} handleAction={handleAction} />
                        </div>
                    ))
                ) : (
                    <div className=" flex flex-col">
                        <div className="w-full border border-borderColorPrimary px-[12px] py-[18px] flex flex-col items-center h-[104px] bg-contentBackground rounded-secondary justify-center">
                            {isLoading.upcoming ? (
                                <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1">{t("Loading")}</p>
                            ) : (
                                <>
                                    <p className="text-sm text-txtAppointmentColor font-medium leading-[18px] -tracking-[0.01em]">You have never made an appointment</p>
                                    <CustomButton outlinePrimary onClick={navigateLocation} className="mt-2 text-sm px-4 !py-2">
                                        Book Now
                                    </CustomButton>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="mb-5 flex flex-col">
                <div className="flex justify-between items-center mb-2">
                    <h2 className="text-sm font-bold text-txtAppointmentColor leading-[18.2px]">{t("Past Appointments")}</h2>
                    <button className="text-sm font-medium leading-[18.2px] -tracking-[0.01em] text-primaryAppointment" onClick={navigateLocation}>
                        + {t("New appointment")}
                    </button>
                </div>
                {bookings.past.length > 0 ? (
                    bookings.past.map((pastBooking: any) => (
                        <div key={`latest - ${pastBooking.id}`} className="cursor-pointer" onClick={handleAction("booking", pastBooking)}>
                            <BookingCard booking={pastBooking} handleAction={handleAction} />
                        </div>
                    ))
                ) : (
                    <div className="mb-5 flex flex-col">
                        <div className="w-full border border-borderColorPrimary px-[12px] py-[18px] flex flex-col items-center h-[104px] bg-contentBackground rounded-secondary justify-center">
                            {isLoading.past ? (
                                <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1">{t("Loading")}</p>
                            ) : (
                                <>
                                    <p className="text-sm text-txtAppointmentColor font-medium leading-[18px] -tracking-[0.01em]">You have never made an appointment</p>
                                    {/* <CustomButton primary onClick={navigateLocation} className="bg-primary text-primaryText px-8 py-3 h-[42px] mt-2">
                                        Book Now
                                    </CustomButton> */}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <Sidebar
                isBack
                isOpen={action.booking}
                isBottomView={uiState.isMobile}
                size="w-[460px]"
                position={uiState.isMobile ? "bottom" : "right"}
                handleClose={handleActionClose}
                handleBack={handleActionClose}
            >
                {action.booking && booking && <BookingView booking={booking} handleClose={handleActionClose} setIsRefresh={setIsRefresh} />}
            </Sidebar>
            <Sidebar
                isBack
                isOpen={action.tip}
                isBottomView={uiState.isMobile}
                size="w-[460px]"
                position={uiState.isMobile ? "bottom" : "right"}
                handleClose={handleActionClose}
                handleBack={handleActionClose}
            >
                {action.tip && booking && <Tip booking={booking} handleClose={handleActionClose} setIsRefresh={setIsRefresh} />}
            </Sidebar>
        </>
    );
};

export default BookingList;
