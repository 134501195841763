import { Sidebar as PrimeSidebar } from "primereact/sidebar";
import React, { ReactNode } from "react";
import { GoArrowLeft } from "react-icons/go";

interface IDrawerProps {
    children: ReactNode;
    isOpen: boolean;
    handleClose: () => void;
    handleBack?: () => void;
    isBack?: boolean;
    customClass?: string;
    size?: string;
    position?: "top" | "bottom" | "left" | "right" | undefined;
    isBottomView?: boolean;
}

const Sidebar = ({ children, isOpen, handleClose, customClass, size, position = "right", handleBack, isBack = false, isBottomView = false }: IDrawerProps) => (
    <PrimeSidebar
        visible={isOpen}
        onHide={handleClose}
        position={position}
        dismissable={true}
        className={`${size} ${customClass} ${isBottomView && "w-full h-[92%] rounded-t-[24px]"} bg-contentBackground font-primary sidebar-wrapper`}
    >
        {isBack && handleBack && (
            <button
                type="button"
                className="w-[36px] h-[36px] bg-contentBackground border border-borderColorSecondary rounded-primary flex items-center justify-center absolute -left-[24px]"
                onClick={handleBack}
            >
                <GoArrowLeft color="var(--brand-color)" />
            </button>
        )}
        {children}
    </PrimeSidebar>
);

export default Sidebar;
