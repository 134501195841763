import React, { useEffect } from "react";
import { SpecilnationmarkSVG, PlaceholderImgPNG } from "src/theme/Images";
import { ISpecialist } from "../../../../Interface";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setIsShowSpecialistProfileModal, setShowSpecialistProfileModalData } from "../../../../redux/reducers/Ui.slice";
import { getBookingInfo, setSelectedSpecialist, setTempStore } from "../../Booking.slice";
import { InfoCircle } from "@untitled-ui/icons-react/build/cjs";

interface IProps {
    specialistData: ISpecialist;
}

const SpecialistCardComponent = ({ specialistData }: IProps) => {
    const dispatch = useAppDispatch();
    const uiStates = useAppSelector((data) => data.UiStates);
    const bookingInfo = useAppSelector(getBookingInfo);

    useEffect(
        () => () => {
            dispatch(setIsShowSpecialistProfileModal(false));
            dispatch(setShowSpecialistProfileModalData(undefined));
        },
        [],
    );
    const handleClick = () => {
        const isSelected = bookingInfo?.tempStore?.selectedSpecialist?.id === specialistData.id;
        dispatch(setTempStore({ selectedSpecialist: isSelected ? undefined : specialistData }));

        if (uiStates.isShowSpecialistProfileModal) {
            dispatch(setShowSpecialistProfileModalData(specialistData));
            dispatch(setIsShowSpecialistProfileModal(true));
        }
    };

    return (
        <div className="w-full" onClick={handleClick} key={specialistData.id}>
            <div
                className={`cursor-pointer border p-3 rounded-secondary h-full hover:border-primary hover:shadow-md bg-productBackgroundColor ${
                    bookingInfo?.tempStore?.selectedSpecialist?.id === specialistData.id ? "border-primary " : "border-borderColorPrimary"
                }  h-auto`}
            >
                <div className="">
                    <img className="rounded-[12px] aspect-square  object-cover" src={specialistData.profile_image_url} alt="" onError={(event) => (event.currentTarget.src = PlaceholderImgPNG)} />
                </div>
                <div className="flex items-end justify-between  pb-1 pt-4 ">
                    <div className="">
                        <h2 className="mb-1  text-sm font-medium text-txtAppointmentColor -tracking-[0.01rem]">
                            {specialistData.first_name} {specialistData.last_name}
                        </h2>
                        <p className="text-textGrey  text-xs font-normal ">{specialistData?.staff_role?.name}</p>
                    </div>
                    <div
                        className="cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(setShowSpecialistProfileModalData(specialistData));
                            dispatch(setIsShowSpecialistProfileModal(true));
                        }}
                    >
                        <InfoCircle color="var(--brand-color)" width={16} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecialistCardComponent;
