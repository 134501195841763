import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { useTranslation } from "react-i18next";
import PhoneOrEmail from "src/components/PhoneOrEmail";
import { usePhone } from "src/hooks/usePhone";
import { DefaultAuthDataInit, IInitAction, ILoginProps } from "../Auth.interface";
import { trim } from "lodash";
import { selectLoading, userPhoneEmailLogin } from "src/redux/reducers/Login.slice";
import { errorCode } from "src/constants/common";
import CustomButton from "src/components/CustomButton";
import { ArrowNarrowLeft } from "@untitled-ui/icons-react/build/cjs";

interface ILogin {
    authData: any;
    setAuthData: any;
    handleAuthAction: (type: keyof IInitAction) => void;
}
const PhoneEmail: FC<ILogin> = ({ setAuthData, handleAuthAction, authData }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const { setFormatPhone } = usePhone();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const loading = useAppSelector(selectLoading);
    const inputType = authData.email && !authData.phone ? "phone" : authData.phone && !authData.email ? "email" : "both";
    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<ILoginProps>({
        defaultValues: {
            emailPhone: "",
        },
    });

    const handleChange = async (data: any) => {
        const info = data.emailPhone;
        if (info.error) {
            setError("emailPhone", {
                type: "manual",
                message: info.error,
            });
            return;
        }
        let payload = {};
        if (!authData.isEmail) {
            const mainNumber = `+${authData.phone.code}${authData.phone.number}`;
            payload = {
                email: info.data,
                phone: mainNumber,
                phone_country_code: authData.phone.country,
            };
        } else {
            const mainNumber = `+${info.data.code}${info.data.number}`;
            payload = {
                email: authData.email,
                phone: mainNumber,
                phone_country_code: info.data.country,
            };
        }

        const result: any = await dispatch(userPhoneEmailLogin(payload));

        if (result.type === userPhoneEmailLogin.fulfilled.toString()) {
            let updateAuthData = { ...authData, isEmail: info.isEmail };

            if (!info.isEmail) {
                const phoneInfo = info.data;
                const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
                const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
                updateAuthData = {
                    ...updateAuthData,
                    phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
                };
            } else {
                updateAuthData = {
                    ...updateAuthData,
                    email: info.data,
                };
            }
            setAuthData(updateAuthData);
            handleAuthAction("otp");
        }
        if (result.type === userPhoneEmailLogin.rejected.toString()) {
            const response = result.payload;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError("emailPhone", {
                            type: "manual",
                            message: response.data[field][0],
                        });
                    });
                } else if (response.message) {
                    setError("emailPhone", {
                        type: "manual",
                        message: response.message,
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };
    const onInputChange = (data: any) => {
        if (trim(data.data)) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    };
    const handleBackToLogin = () => {
        setAuthData(DefaultAuthDataInit);
        handleAuthAction("login");
    };
    return (
        <>
            <div className="font-primary">
                <div className="w-full">
                    <h1 className="login-title-text ">{!authData.isEmail ? t("Enter your email address") : t("Enter your mobile number")}</h1>
                    <p className="login-subtitle-text">
                        {!authData.isEmail ? t("We will use your email address to recover your account") : t("We will use your mobile number to recover your account")}
                        {/* {authData.email && !authData.phone
                            ? t('Add your mobile to aid in account recovery')
                            : authData.phone && !authData.email
                            ? t('Enter your email address')
                            : t('Enter your phone number or email address')} */}
                    </p>
                    <div className="mt-[12px]">
                        <form onSubmit={handleSubmit(handleChange)}>
                            <div className="flex flex-wrap">
                                <p className=" text-sm mb-1 text-txtAppointmentColor font-medium leading-[140%]">{!authData.isEmail ? "Email" : "Mobile"}</p>
                                <Controller
                                    name="emailPhone"
                                    control={control}
                                    render={({ field: { onChange } }: any) => (
                                        <div className="flex flex-col w-full">
                                            <PhoneOrEmail
                                                onNumberChange={(data: any) => {
                                                    onChange(data);
                                                    onInputChange(data);
                                                }}
                                                errors={errors.emailPhone}
                                                singleType={!authData.isEmail ? "email" : "phone"}
                                                inputType={inputType}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="mt-5 mx-auto w-full flex"></div>
                            <div className=" mt-[172px] w-full bottom-0 flex flex-col gap-2">
                                <CustomButton
                                    type="button"
                                    icon={<ArrowNarrowLeft width={16} />}
                                    className=" !border-0 ring-0  !bg-transparent text-center text-txtAppointmentColor w-full"
                                    shadow={false}
                                    onClick={handleBackToLogin}
                                >
                                    {t("Back")}
                                </CustomButton>
                                <CustomButton
                                    type="submit"
                                    isLoading={loading}
                                    disabled={loading || isButtonDisabled}
                                    primary
                                    className={`fl-btn p-3 bg-primary text-white font-bold w-full rounded-secondary transition-all ease-in-out delay-75`}
                                >
                                    <span>{"Continue"}</span>
                                </CustomButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PhoneEmail;
