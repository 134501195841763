import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { calculateTotalTaxes } from "../../../../utils/global-functions";
import { useTranslation } from "react-i18next";

const BasketCalculation = () => {
    const { t } = useTranslation();
    const bookingState = useAppSelector((data) => data.Booking);
    const [servicesSubTotalPrice, setServicesSubTotalPrice] = useState("");
    const [servicesSubTotalTax, setServicesSubTotalTax] = useState("0.00");
    const [productSubTotalPrice, setProductSubTotalPrice] = useState("0.00");

    useEffect(() => {
        const totalServicesPrices = bookingState.selectedServices.reduce((prevPrice, serviceRecord) => {
            if (serviceRecord.price && serviceRecord.quantity) {
                return prevPrice + Number(serviceRecord.price?.price) * serviceRecord.quantity;
            }
            return prevPrice;
        }, 0);
        const totalProductsPrices = bookingState.selectedProducts.reduce((prevPrice, ProductRecord) => {
            if (ProductRecord?.selectedVariant?.inventory.price) {
                return prevPrice + Number(ProductRecord?.selectedVariant?.inventory.price);
            }
            return prevPrice;
        }, 0);
        setProductSubTotalPrice(totalProductsPrices);
        setServicesSubTotalPrice(totalServicesPrices.toFixed(2));
        if (totalServicesPrices && bookingState.selectedShopLocation?.sales_tax) {
            const subTotalTax = calculateTotalTaxes(
                totalServicesPrices.toFixed(2),
                Number(bookingState.selectedShopLocation?.sales_tax?.gst),
                Number(bookingState.selectedShopLocation?.sales_tax?.hst),
                Number(bookingState.selectedShopLocation?.sales_tax?.pst),
            );
            setServicesSubTotalTax(subTotalTax);
        } else {
            setServicesSubTotalTax("0.00");
        }
    }, [bookingState.selectedServices, bookingState.selectedProducts]);

    return (
        <div>
            {bookingState.isModifyingAppointment && bookingState?.selectedPromocode?.name && (
                <div className="flex justify-between mt-5">
                    <h1 className="text-base leading-[22.4px] font-medium text-textGrey -tracking-[0.03rem]">{t("Promo code")}</h1>
                    <span className="text-base leading-[22.4px] font-medium text-txtAppointmentColor -tracking-[0.03rem]">{bookingState?.selectedPromocode?.name}</span>
                </div>
            )}
            <div className="flex justify-between mt-3">
                <h1 className="text-base leading-[22.4px] font-medium text-textGrey -tracking-[0.03rem]">{t("Subtotal")}</h1>
                <span className="text-base leading-[22.4px] font-medium text-txtAppointmentColor -tracking-[0.03rem]">${parseFloat(servicesSubTotalPrice) + parseFloat(productSubTotalPrice)}</span>
            </div>
            <div className="flex justify-between mt-3">
                <h1 className="text-base leading-[22.4px] font-medium text-textGrey -tracking-[0.03rem]">{t("Taxes and fees")}</h1>
                <span className="text-base leading-[22.4px] font-medium text-txtAppointmentColor -tracking-[0.03rem]">${servicesSubTotalTax}</span>
            </div>
            <hr className="border-borderColorSecondary my-4" />
            <div className="font-bold mt-2 flex justify-between">
                <h1 className="text-base leading-[22.4px] font-medium text-txtAppointmentColor -tracking-[0.03rem]">{t("Total")}</h1>
                <span className="text-base leading-[22.4px] font-medium text-txtAppointmentColor -tracking-[0.03rem]">
                    ${(parseFloat(servicesSubTotalPrice) + parseFloat(productSubTotalPrice) + parseFloat(servicesSubTotalTax)).toFixed(2)}
                </span>
            </div>
        </div>
    );
};

export default BasketCalculation;
