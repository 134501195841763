import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { INotification } from "./Profile.interface";
import { axiosPatch } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { currentUser, me } from "src/redux/reducers/common/Common.slice";
import { toast } from "react-toastify";
import CustomButton from "src/components/CustomButton";

const NotificationSetting = ({ handleClose }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState<INotification>({
        promotions: "all",
        booking_confirm: "all",
        booking_reschedule: "all",
        booking_canceled: "all",
        whats_new: "all",
        referral: "all",
    });

    useEffect(() => {
        if (user) {
            setNotification(user.notification);
        }
    }, [user]);

    const handleSave = () => {
        setIsLoading(true);
        axiosPatch(API.NOTIFICATION.CREATE_UPDATE, notification)
            .then(async () => {
                await dispatch(me());
                handleClose();
            })
            .catch((error) => toast.error(error?.message))
            .finally(() => setIsLoading(false));
    };

    const handleChange = (type: string, value: string) => () => {
        setNotification((old) => ({ ...old, [type]: value }));
    };

    const settings = useMemo(
        () => [
            { name: t("Promotions"), type: "promotions", value: notification.promotions },
            { name: t("Confirmed Appointments"), type: "booking_confirm", value: notification.booking_confirm },
            { name: t("Rescheduled Appointments"), type: "booking_reschedule", value: notification.booking_reschedule },
            { name: t("Cancelled Appointments"), type: "booking_canceled", value: notification.booking_canceled },
            { name: t("What's New"), type: "whats_new", value: notification.whats_new },
            { name: t("Referral Program"), type: "referral", value: notification.referral },
        ],
        [t, notification],
    );

    const options = useMemo(
        () => [
            { name: "None", value: "none" },
            { name: "Both", value: "all" },
            { name: "Email", value: "email" },
            { name: "SMS", value: "sms" },
        ],
        [],
    );

    return (
        <div className="relative w-[100%] flex flex-col  h-[calc(100%-20px)] font-primary overflow-hidden">
            <div className="flex flex-col mb-5 mt-1">
                <h3 className="location-title-text">Notifications</h3>
                <span className="location-sub-text">Manage when you’ll receive notifications</span>
            </div>
            <div className=" h-[calc(100%-150px)] overflow-auto pb-[40px] pt-3">
                {settings.map((setting: any, index) => (
                    <div key={index} className="mb-5">
                        <div className="flex flex-col">
                            <h1 className="text-base  font-medium text-txtAppointmentColor ">{setting.name}</h1>
                            <span className="location-sub-text">Lorem Ipsum is simply dummy text.</span>
                        </div>
                        <div className="flex border rounded-lg w-[325px] border-borderColorPrimary mt-4">
                            {options.map((option, key) => (
                                <button
                                    key={key}
                                    className={`flex justify-center items-center h-[38px] border-borderColorPrimary  px-6 transition-all ease-in-out delay-100 font-semibold text-base bg-transparent ${
                                        setting.value === option.value ? "button-active" : ""
                                    } ${key === 1 ? "border-l border-r" : key === 2 ? "border-r" : ""}`}
                                    onClick={handleChange(setting.type, option.value)}
                                >
                                    <span className="font-normal text-sm pt-[1px]">{option.name}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-auto absolute bottom-0 w-full flex gap-3 bg-contentBackground pt-2">
                <CustomButton outlineSecondary size="w-1/2" disabled={isLoading} onClick={handleClose}>
                    Cancel
                </CustomButton>
                <CustomButton primary size="w-1/2" disabled={isLoading} isLoading={isLoading} onClick={handleSave}>
                    Save changes
                </CustomButton>
            </div>
        </div>
    );
};

export default NotificationSetting;
